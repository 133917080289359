import { actionTypes } from 'actions/user';

export const initialState: IUserState = {
  borrower: null,
  isRenewingBorrower: false,
  isFetchingBorrower: false,
  isFetchedBorrower: false,
  isCreatingApplicationUrl: false,
  signInSuccessUrl: '',
};

export default function userReducer(state: IUserState = initialState, action: IAction<any>): IUserState {
  switch (action.type) {
    case actionTypes.GET_CACHED_BORROWER_REQUEST: {
      return {
        ...state,
        isFetchingBorrower: true,
      };
    }
    case actionTypes.GET_CACHED_BORROWER_SUCCESS: {
      return {
        ...state,
        borrower: action.payload,
        isFetchingBorrower: false,
        isFetchedBorrower: true,
      };
    }

    case actionTypes.RENEW_BORROWER_REQUEST: {
      return {
        ...state,
        isRenewingBorrower: true,
      };
    }
    case actionTypes.RENEW_BORROWER_SUCCESS: {
      return {
        ...state,
        isRenewingBorrower: false,
      };
    }
    case actionTypes.RENEW_BORROWER_ERROR: {
      return {
        ...state,
        isRenewingBorrower: false,
      };
    }

    // Application URL
    case actionTypes.CREATE_APPLICATION_URL: {
      return {
        ...state,
        isCreatingApplicationUrl: true,
      };
    }
    case actionTypes.CREATE_APPLICATION_URL_SUCCESS:
    case actionTypes.CREATE_APPLICATION_URL_ERROR: {
      return {
        ...state,
        isCreatingApplicationUrl: false,
      };
    }

    case actionTypes.SET_SIGN_IN_SUCCESS_URL: {
      return {
        ...state,
        signInSuccessUrl: action.payload,
      };
    }

    default:
      return state;
  }
}
