import { useSelectedLoan } from 'hooks';
import { formatDate } from 'utils/format';

const useWipPromoEndDate = () => {
  const selectedLoan = useSelectedLoan();
  const expirationDate = selectedLoan && selectedLoan.sacExpirationDate;
  if (
    !selectedLoan ||
    selectedLoan.isPending ||
    selectedLoan.sacIneligibleDate ||
    !selectedLoan.isStillInPromotionalPeriod ||
    !expirationDate
  ) {
    return null;
  }
  return formatDate(expirationDate, { outputFormat: 'MM/DD/YY' });
};

export default useWipPromoEndDate;
