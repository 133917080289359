import axios, { AxiosError } from 'axios';

export const REGEXES: { [field: string]: RegExp } = {
  NUMBER_ONLY: new RegExp(/^[0-9]*$/),
  NUMBER_AND_LETTERS_ONLY: new RegExp(/^[a-zA-Z0-9_.-]*$/),
  PASSWORD: new RegExp(/(?=.*[a-zA-Z])(?=.+[0-9])(?=.*[#$^+=!*()@%&"',-./:;<>?\\|~{}`_\\[\] ]).+/),
  EMAIL: new RegExp(
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
  NUMBERS_AND_PERIOD: new RegExp(/[0-9.]/),
};

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 50;

export const PASSWORD_ERROR_MESSAGES = {
  INVALID_LENGTH: 'Password must be 8 to 50 characters long',
  MISSING_REQUIREMENT: 'Password must be any combination of letters, numbers, and symbols',
  NOT_MATCH: 'Password does not match',
};

export const isDecimalNumber = (value: string) => {
  const regex = /^([0-9]+(\.?[0-9]*[0-9]*)?)$/g;
  const valueWithoutComma = value.replace(/,/g, '');
  return regex.test(valueWithoutComma);
};

export const validatePassword = (password: string): Nullable<string> => {
  if (password.length > PASSWORD_MAX_LENGTH || password.length < PASSWORD_MIN_LENGTH) {
    return PASSWORD_ERROR_MESSAGES.INVALID_LENGTH;
  }

  const passwordRegex = new RegExp(REGEXES.PASSWORD);
  if (!passwordRegex.test(password)) {
    return PASSWORD_ERROR_MESSAGES.MISSING_REQUIREMENT;
  }

  return null;
};

type ErrorMessageType = {
  message: string;
};
export const extractErrorMessage = (error: unknown) => {
  let errorMessage = 'An unknown error occurred';
  if (axios.isAxiosError(error)) {
    const e = error as AxiosError<ErrorMessageType>;
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage = e.response.data.message;
    } else if (e.message) {
      errorMessage = e.message;
    }
  }
  return errorMessage;
};
