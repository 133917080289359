import { Box, Divider } from '@mui/material';
import { IconLink } from 'components/widgets';
import { IconUserAccountFaqs, IconUserAccountSupportEmail, IconUserAccountSupport } from 'assets/icons';

import './Footer.scss';
import { SCRATCHPAY_FAQS_URL, SCRATCHPAY_SUPPORT_PHONE_NUM, SCRATCHPAY_SUPPORT_EMAIL } from 'utils/constants';

const Footer = () => {
  return (
    <Box styleName="wrapper">
      <div styleName="help">Need help?</div>
      <div styleName="links">
        <div styleName="link">
          <IconLink isExternal path={SCRATCHPAY_FAQS_URL} text="Read FAQs" icon={<IconUserAccountFaqs />} />
        </div>
        <Divider orientation="vertical" styleName="divider" flexItem />
        <div styleName="link">
          <IconLink
            isExternal
            path={`tel:${SCRATCHPAY_SUPPORT_PHONE_NUM}`}
            text="Call Support"
            icon={<IconUserAccountSupport />}
          />
        </div>
        <Divider orientation="vertical" styleName="divider" flexItem />
        <div styleName="link">
          <IconLink
            isExternal
            path={`mailto:${SCRATCHPAY_SUPPORT_EMAIL}`}
            text="Email Support"
            icon={<IconUserAccountSupportEmail />}
          />
        </div>
      </div>
      <div styleName="webbank-language">
        Scratch Pay plans in the US are issued by WebBank, while in Canada, 
        they are issued by ©Scratch Financial, Inc. ©Scratchpay (NMLS ID#: 1582666). 
        Eligibility is required for these loan products, and Scratch Pay 
        plans may not be available in all states.
      </div>
    </Box>
  );
};

export default Footer;
