import { getOwedAmountInDollars } from 'utils/loan';
import useSelectedLoan from './useSelectedLoan';
import { useSelector } from 'react-redux';
import * as loanSelectors from 'selectors/loan';
import * as convenienceFeeSelectors from 'selectors/convenienceFee';

const usePayoffAmount = () => {
  let selectedLoan = useSelectedLoan();
  const isFetched = useSelector(loanSelectors.isFetchedSelector);
  const allLoans = useSelector(loanSelectors.loansSelector);
  if (isFetched && !selectedLoan) {
    selectedLoan = allLoans[0];
  }
  const convenienceFee = useSelector(convenienceFeeSelectors.convenienceFeeSelector);
  const convenienceFeeValue = convenienceFee.value / convenienceFee.unit || 0;

  let nextScheduledPaymentDue = useSelector(loanSelectors.getNextPaymentDueSelector(selectedLoan?.id || ''));
  let isFetchingConvenienceFee = useSelector(convenienceFeeSelectors.isFetchingConvenienceFeeSelector);
  const lateFeeValue = selectedLoan?.currentLateFeesPaymentAmountNeedToPay || 0;
  if (!selectedLoan) {
    nextScheduledPaymentDue = null;
    isFetchingConvenienceFee = false;
  }

  let isPayoffAmount = false;
  let paymentAmount: number;
  const owedAmount = getOwedAmountInDollars(nextScheduledPaymentDue);
  if (selectedLoan && selectedLoan.currentPayoffBalance && owedAmount > selectedLoan.currentPayoffBalance) {
    isPayoffAmount = true;
    paymentAmount = selectedLoan.currentPayoffBalance;
  } else {
    paymentAmount = owedAmount;
  }

  // useful for displaying on UI
  const additionalFees = convenienceFeeValue + lateFeeValue;

  // actual amount sent to PS, convenience fee not included here
  const actualNextInstallment = paymentAmount + lateFeeValue;
  // amount displayed to user
  const totalNextInstallment = actualNextInstallment + convenienceFeeValue;

  return {
    isPayoffAmount,
    paymentAmount,
    isFetchingConvenienceFee,
    convenienceFeeValue,
    lateFeeValue,
    additionalFees,
    actualNextInstallment,
    totalNextInstallment,
  };
};

export default usePayoffAmount;
