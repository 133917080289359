import { normalize, schema } from 'normalizr';

import { actionTypes } from 'actions/loan';

const loanSchema = new schema.Entity('loanById');
const loanListSchema = [loanSchema];

export const initialState: ILoanState = {
  isSynchronizing: false,
  isFetched: false,
  isFetching: false,
  isTogglingAutopayById: {},
  isSettingPrimaryPaymentMethodById: {},
  loanById: {},
  loanIds: [],
  selectedLoanId: '',
};

export default function loanReducer(state: ILoanState = initialState, action: IAction<any>): ILoanState {
  switch (action.type) {
    case actionTypes.GET_CACHING_LOANS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case actionTypes.GET_CACHING_LOANS_SUCCESS: {
      const { payload: loans } = action;
      const {
        entities: { loanById },
        result: loanIds,
      } = normalize(loans, loanListSchema);
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        loanById: { ...loanById },
        loanIds: [...loanIds],
      };
    }

    case actionTypes.SYNCHRONIZE_LOANS_REQUEST: {
      return {
        ...state,
        isSynchronizing: true,
      };
    }
    case actionTypes.SYNCHRONIZE_LOANS_SUCCESS: {
      return {
        ...state,
        isSynchronizing: false,
      };
    }
    case actionTypes.SYNCHRONIZE_LOANS_ERROR: {
      return {
        ...state,
        isSynchronizing: false,
      };
    }

    case actionTypes.TOGGLE_AUTOPAY: {
      const {
        payload: {
          data: { loanId },
        },
      } = action;
      return {
        ...state,
        isTogglingAutopayById: {
          ...state.isTogglingAutopayById,
          [loanId]: true,
        },
      };
    }
    case actionTypes.TOGGLE_AUTOPAY_SUCCESS: {
      const { payload: loanId } = action;
      return {
        ...state,
        isTogglingAutopayById: {
          ...state.isTogglingAutopayById,
          [loanId]: false,
        },
      };
    }
    case actionTypes.TOGGLE_AUTOPAY_ERROR: {
      const { payload: loanId } = action;
      return {
        ...state,
        isTogglingAutopayById: {
          ...state.isTogglingAutopayById,
          [loanId]: false,
        },
      };
    }

    case actionTypes.SET_PRIMARY_PAYMENT_METHOD: {
      const {
        payload: {
          data: { loanId },
        },
      } = action;
      return {
        ...state,
        isSettingPrimaryPaymentMethodById: {
          ...state.isSettingPrimaryPaymentMethodById,
          [loanId]: true,
        },
      };
    }
    case actionTypes.SET_PRIMARY_PAYMENT_METHOD_SUCCESS: {
      const { payload: loanId } = action;
      return {
        ...state,
        isSettingPrimaryPaymentMethodById: {
          ...state.isSettingPrimaryPaymentMethodById,
          [loanId]: false,
        },
      };
    }
    case actionTypes.SET_PRIMARY_PAYMENT_METHOD_ERROR: {
      const { payload: loanId } = action;
      return {
        ...state,
        isSettingPrimaryPaymentMethodById: {
          ...state.isSettingPrimaryPaymentMethodById,
          [loanId]: false,
        },
      };
    }

    case actionTypes.SET_SELECTED_LOAN_ID: {
      return {
        ...state,
        selectedLoanId: action.payload,
      };
    }
    default:
      return state;
  }
}
