import { actionTypes } from 'actions/documents';

export const initialState: IDocumentsState = {
    documents: [],
    isFetchingDocuments: false,
    isFetchedDocuments: false,
    totalDocuments: 0,
};

export default function documentsReducer(state: IDocumentsState = initialState, action: IAction<any>): IDocumentsState {
    switch (action.type) {
        // Documents
        case actionTypes.GET_DOCUMENTS_REQUEST: {
            return {
                ...state,
                isFetchingDocuments: true,
            };
        }
        case actionTypes.GET_DOCUMENTS_SUCCESS: {
            const { payload: documents } = action;
            const loanWithDocuments = documents.filter((loanDocuments: ILoanDocuments) => loanDocuments.documents.length);
            const totalDocuments = loanWithDocuments.reduce(
                (previousValue: number, loanDocument: ILoanDocuments) => previousValue + loanDocument.documents.length,
                0
            )
            return {
                ...state,
                isFetchingDocuments: false,
                isFetchedDocuments: true,
                documents: loanWithDocuments,
                totalDocuments: totalDocuments,
            };
        }
        case actionTypes.GET_DOCUMENTS_ERROR: {
            return {
                ...state,
                isFetchingDocuments: false,
                isFetchedDocuments: true,
            };
        }
        default:
            return state;
    }
}
