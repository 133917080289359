import { normalize, schema } from 'normalizr';

import { actionTypes } from 'actions/paymentMethod';

const paymentMethodSchema = new schema.Entity('paymentMethodById');
const paymentMethodListSchema = [paymentMethodSchema];

export const initialState: IPaymentMethodState = {
  isFetching: false,
  isFetched: false,
  isAdding: false,
  isDeleting: false,
  paymentMethodById: {},
  paymentMethodIds: [],
  selectedPaymentMethodId: null,
  plaidLinkToken: null,
  isFetchingPlaidLinkToken: false,
  isFetchedPlaidLinkToken: false,
};

export default function paymentMethodReducer(
  state: IPaymentMethodState = initialState,
  action: IAction<any>
): IPaymentMethodState {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_METHODS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case actionTypes.GET_PAYMENT_METHODS_SUCCESS: {
      const { payload: paymentMethods } = action;
      const {
        entities: { paymentMethodById },
        result: paymentMethodIds,
      } = normalize(paymentMethods, paymentMethodListSchema);
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        paymentMethodById: {
          ...state.paymentMethodById,
          ...paymentMethodById,
        },
        paymentMethodIds,
      };
    }
    case actionTypes.GET_PAYMENT_METHODS_ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
      };
    }

    case actionTypes.ADD_PAYMENT_METHOD: {
      return {
        ...state,
        isAdding: true,
      };
    }
    case actionTypes.ADD_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        isAdding: false,
      };
    }
    case actionTypes.ADD_PAYMENT_METHOD_ERROR: {
      return {
        ...state,
        isAdding: false,
      };
    }

    case actionTypes.DELETE_PAYMENT_METHOD: {
      return {
        ...state,
        isDeleting: true,
      };
    }
    case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS: {
      const { payload: deletedTokenId } = action;
      const paymentMethodIds = state.paymentMethodIds.filter((id) => id !== deletedTokenId);
      const paymentMethodById = { ...state.paymentMethodById };
      delete paymentMethodById[deletedTokenId];
      return {
        ...state,
        isDeleting: false,
        selectedPaymentMethodId: null,
        paymentMethodIds,
        paymentMethodById,
      };
    }
    case actionTypes.DELETE_PAYMENT_METHOD_ERROR: {
      return {
        ...state,
        isDeleting: false,
      };
    }

    case actionTypes.SELECT_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethodId: action.payload,
      };
    }

    case actionTypes.GET_PLAID_LINK_TOKEN: {
      return {
        ...state,
        plaidLinkToken: null,
        isFetchingPlaidLinkToken: true,
      };
    }
    case actionTypes.GET_PLAID_LINK_TOKEN_SUCCESS: {
      const { payload: plaidLinkToken } = action;
      return {
        ...state,
        plaidLinkToken,
        isFetchingPlaidLinkToken: false,
        isFetchedPlaidLinkToken: true,
      };
    }
    case actionTypes.GET_PLAID_LINK_TOKEN_ERROR: {
      return {
        ...state,
        plaidLinkToken: null,
        isFetchingPlaidLinkToken: false,
        isFetchedPlaidLinkToken: true,
      };
    }
    default:
      return state;
  }
}
