import { isCordovaAndroidApp, isCordovaApp, isCordovaIOSApp } from './constants';
import { saveAs } from 'file-saver';

export const getMimeType = (fileExt: string) => {
  let mimeType = '';

  if (fileExt === 'png' || fileExt === 'jpg' || fileExt === 'jpeg') {
    mimeType = 'image/*';
  }
  if (fileExt === 'pdf') {
    mimeType = 'application/pdf';
  }
  if (fileExt === 'avi' || fileExt === 'mp4' || fileExt === 'mov') {
    mimeType = 'video/*';
  }
  return mimeType;
};

export const getFileExtension = (fileKey: string): string => {
  return `${fileKey.split('.').pop()}`;
};

export const getStorageLocation = () => {
  if (isCordovaAndroidApp) {
    return window.cordova.file.externalRootDirectory + 'Download/';
  }
  if (isCordovaIOSApp) {
    return window.cordova.file.documentsDirectory;
  }
};

export const getCompleteFileName = (fileName: string, fileKey: string) => {
  return [fileName, '.', getFileExtension(fileKey)].join('');
};

const errorHandler = (err: any) => {
  Promise.reject(err);
};

export const saveFile = (dataBuffer: any, fileName: string) => {
  const blob = new Blob([dataBuffer], { type: getMimeType(fileName) });
  try {
    if (isCordovaApp) {
      // Read the system location and create a file
      window.resolveLocalFileSystemURL(
        getStorageLocation(),
        (dirEntry: any) => {
          createFile(dirEntry, fileName, blob);
        },
        errorHandler,
      );
    } else {
      saveAs(blob, fileName);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Something went wrong while saving file::', err);
    return false;
  }
  return true;
};

// Create a file
function createFile(dirEntry: any, fileName: string, blob: any) {
  //   Creates a new file or returns the file if it already exists.
  dirEntry.getFile(
    fileName,
    { create: true, exclusive: false },
    function (fileEntry: any) {
      writeFile(fileEntry, blob);
    },
    errorHandler,
  );
}

// Write to the file
function writeFile(fileEntry: any, dataObj: any) {
  // Create a FileWriter object for our FileEntry (log.txt).
  fileEntry.createWriter(function (fileWriter: any) {
    fileWriter.onwriteend = function () {
      // eslint-disable-next-line no-console
      console.log('Successful file write...');
    };
    fileWriter.onerror = errorHandler;
    fileWriter.write(dataObj);
  });
}
