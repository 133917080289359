import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';

import { combinedReducers } from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production', // Enable DevTools only in development
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: ['DOWNLOAD_DOCUMENT'],
      },
      thunk: false,
    }).concat(sagaMiddleware),
  reducer: combinedReducers,
});

sagaMiddleware.run(rootSaga);

export default store;
export type RootState = ReturnType<typeof combinedReducers>;
export type AppDispatch = typeof store.dispatch;
