import { createSelector } from '@reduxjs/toolkit';
import { chain } from 'lodash';

import { checkIsLateScheduledPayment } from 'utils/payment';

export const paymentStateSelector = (state: IRootState) => state.payment;

export const paymentByIdSelector = createSelector(paymentStateSelector, ({ paymentById }) => paymentById);

export const paymentsSelector = createSelector(paymentStateSelector, ({ paymentById, paymentIds }) =>
  paymentIds.map((id) => paymentById[id]),
);

export const loanPaymentsSelector = (loanId: string) =>
  createSelector(paymentStateSelector, ({ paymentById, paymentIds }) => {
    const payments = paymentIds.map((id) => paymentById[id]);
    return chain(payments)
      .filter((sp) => sp.loanId === loanId)
      .sortBy(({ transactionDate }) => transactionDate)
      .value();
  });

export const isFetchingPaymentsSelector = createSelector(
  paymentStateSelector,
  ({ isFetchingPayments }) => isFetchingPayments,
);

export const isFetchedPaymentsSelector = createSelector(
  paymentStateSelector,
  ({ isFetchedPayments }) => isFetchedPayments,
);

export const scheduledPaymentByIdSelector = createSelector(
  paymentStateSelector,
  ({ scheduledPaymentById }) => scheduledPaymentById,
);

export const scheduledPaymentsSelector = createSelector(
  paymentStateSelector,
  ({ scheduledPaymentById, scheduledPaymentIds }) => {
    const scheduledPayments = scheduledPaymentIds.map((id) => scheduledPaymentById[id]);
    return chain(scheduledPayments)
      .orderBy(({ dueAt }) => dueAt, 'desc')
      .value();
  },
);

export const loanScheduledPaymentsSelector = (loanId: string) =>
  createSelector(paymentStateSelector, ({ scheduledPaymentById, scheduledPaymentIds }) => {
    const scheduledPayments = scheduledPaymentIds.map((id) => scheduledPaymentById[id]);
    return chain(scheduledPayments)
      .filter((sp) => sp.loanId === loanId)
      .sortBy(({ dueAt }) => dueAt)
      .value();
  });

export const loanNextScheduledPaymentSelector = (loanId: string) =>
  createSelector(loanScheduledPaymentsSelector(loanId), (loanScheduledPayments) =>
    loanScheduledPayments.find(({ isNextPayment }) => isNextPayment),
  );

export const loanLateScheduledPaymentCountSelector = (loanId: string) =>
  createSelector(
    loanScheduledPaymentsSelector(loanId),
    (loanScheduledPayments) => loanScheduledPayments.filter(checkIsLateScheduledPayment).length,
  );

export const nextScheduledPaymentByLoanIdSelector = createSelector(scheduledPaymentsSelector, (scheduledPayments) => {
  return chain(scheduledPayments)
    .filter(({ isNextPayment }) => isNextPayment)
    .groupBy(({ loanId }) => loanId)
    .mapValues((loanScheduledPayments) => loanScheduledPayments[0])
    .value();
});

export const selectedPaymentIdSelector = createSelector(
  paymentStateSelector,
  ({ selectedPaymentId }) => selectedPaymentId,
);

export const selectorPaymentFieldIndex = createSelector(
  paymentStateSelector,
  ({ selectedPaymentFieldIndex }) => selectedPaymentFieldIndex,
);

export const selectedPaymentSelector = createSelector(
  paymentStateSelector,
  scheduledPaymentsSelector,
  ({ selectedPaymentId, paymentById, scheduledPaymentById }: IPaymentState, scheduledPayments) => {
    if (!selectedPaymentId) return { data: null };

    const selectedScheduledPayment = scheduledPaymentById[selectedPaymentId];
    if (selectedScheduledPayment) {
      return { isScheduledPayment: true, data: selectedScheduledPayment };
    }

    const scheduledPaymentCorrespondingToPayment = scheduledPayments.find(
      ({ transaction }) => transaction && transaction.orderId === selectedPaymentId,
    );

    return scheduledPaymentCorrespondingToPayment
      ? { isScheduledPayment: true, data: scheduledPaymentCorrespondingToPayment }
      : { isScheduledPayment: false, data: paymentById[selectedPaymentId] };
  },
);
