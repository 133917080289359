import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import theme from 'utils/theme';
import { isCordovaApp } from 'utils/constants';
import { ErrorBoundary } from './components/layouts';

import store from './store';
import App from './App';

import './styles/global.scss';

const Root = () => {
  const Router: any = isCordovaApp ? HashRouter : BrowserRouter;

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default Root;
