import { isNil } from 'lodash';

import { formatHiddenDigitsWithLast4 } from 'utils/format';

import IconPaymentMethod from './IconPaymentMethod';
import PaymentMethodLogo from '../PaymentMethodLogo';

import './PaymentMethodListItem.scss';

interface IProps {
  method: IPaymentMethod;
  displayHoldername?: boolean;
  displayHiddenDigits?: boolean;
}

const PaymentMethodListItem = (props: IProps) => {
  const { method, displayHoldername = true, displayHiddenDigits = true } = props;
  const { paymentType, holderName, last4, card } = method;
  const hiddenDigits = formatHiddenDigitsWithLast4(paymentType, last4);

  return (
    <div styleName="wrapper">
      {displayHoldername && (
        <div styleName="account-holder">
          <IconPaymentMethod paymentType={paymentType} />
          <span styleName="holder-name">{holderName}</span>
        </div>
      )}
      <div styleName="account-number">
        <PaymentMethodLogo isCard={!isNil(card)} cardBrand={card ? card.cardBrand : undefined} variant="large" />
        <span styleName="digits">
          <span styleName="hidden-digits">{displayHiddenDigits ? hiddenDigits : `Ending in *${last4}`}</span>
        </span>
      </div>
    </div>
  );
};

export default PaymentMethodListItem;
