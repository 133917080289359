import { Fragment, useCallback, useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toggleAutopay } from 'actions/loan';
import { routes } from 'utils/routeHelper';

import { Footer } from 'components/layouts';
import { Col, IconWrapper, AutopayActivationBody, GifAnimationWrapper } from 'components/widgets';

import './AutopayActivation.scss';
import { IconScratchpayHeart } from '../../assets/icons';

import { useSelectedLoan } from 'hooks';
import * as loanSelectors from 'selectors/loan';
import * as paymentSelectors from 'selectors/payment';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import { buildAutopayAuthValue, buildPaymentMethodValue, buildPaymentMethodView } from 'utils/autopayAuth';
import { useAppDispatch } from 'redux-hooks';

export const AutopayActivation = () => {
  const [isEnablingAutopay, setIsEnablingAutopay] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // get selected loan
  const loan = useSelectedLoan();

  // get borrower payment methods
  const isFetchingOrSynchingLoans = useSelector(loanSelectors.isFetchingOrSynchingSelector);
  const isFetchingPayment = useSelector(paymentSelectors.isFetchingPaymentsSelector);
  const isFetchingPaymentMethod = useSelector(paymentMethodSelectors.isFetchingSelector);
  const isFetching = isFetchingOrSynchingLoans || isFetchingPayment || isFetchingPaymentMethod;
  const paymentMethods = useSelector(paymentMethodSelectors.paymentMethodsSelector);
  const selectedMethod = useSelector(paymentMethodSelectors.selectedPaymentMethodSelector);

  const loanView = useMemo(() => buildAutopayAuthValue(loan), [loan]);
  const paymentMethod = useMemo(
    () => buildPaymentMethodValue(selectedMethod, paymentMethods),
    [paymentMethods, selectedMethod],
  );
  const paymentMethodView = useMemo(() => buildPaymentMethodView(paymentMethod), [paymentMethod]);
  const autopayAuthTerms = useMemo(() => {
    return {
      loanId: loanView.loanId,
      paymentStartDate: loanView.paymentStartDate,
      paymentAmount: loanView.paymentAmount,
      paymentMethodLast4: paymentMethodView,
      remainingInstallments: loanView.remainingInstallments,
      frequency: loanView.frequency,
    } as IAutopayAuth;
  }, [loanView, paymentMethodView]);

  const handleEnableAutopay = useCallback(() => {
    setIsEnablingAutopay(true);
    dispatch(
      toggleAutopay({
        data: {
          loanId: loanView.loanId,
          tokenId: paymentMethod.id,
          isEnabled: true,
        },
        onSuccess: () => {
          setIsEnablingAutopay(false);
          navigate(routes.HOME);
        },
        onError: () => {
          setIsEnablingAutopay(false);
        },
      }),
    );
  }, [dispatch, loanView.loanId, navigate, paymentMethod.id]);

  if (!loan) {
    return <Navigate to={routes.HOME} replace />;
  }

  if (!paymentMethod || !paymentMethod.id) {
    return <Navigate to={routes.PAYMENT_METHOD_DETAILS} replace />;
  }

  return (
    <Fragment>
      <div styleName="wrapper">
        <Col xs={12} sm={6} md={4} styleName="col">
          <IconWrapper>
            <IconScratchpayHeart />
          </IconWrapper>
          <AutopayActivationBody
            autopayAuthTerms={autopayAuthTerms}
            handleEnableAutopay={handleEnableAutopay}
            amountDue={loanView.delinquentAmount || 0}
          />
        </Col>
        <Footer />
      </div>
      {(isFetching || isEnablingAutopay) && <GifAnimationWrapper />}
    </Fragment>
  );
};

export default AutopayActivation;
