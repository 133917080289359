import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: IPrimaryPaymentMethodInfoState = {
  isAddPrimaryPaymentMethod: false,
  loanId: null,
  tokenId: null,
};

const primaryPaymentMethodInfoSlice = createSlice({
  name: 'primaryPaymentMethodInfo',
  initialState,
  reducers: {
    setIsAddPrimaryPaymentMethod: (state, action: PayloadAction<boolean>) => {
      state.isAddPrimaryPaymentMethod = action.payload;
    },
    setPrimaryPaymentMethodInfo: (state, action: PayloadAction<{ loanId: string; tokenId: string }>) => {
      state.loanId = action.payload.loanId;
      state.tokenId = action.payload.tokenId;
    },
    resetPrimaryPaymentMethodInfo: (state) => {
      state.isAddPrimaryPaymentMethod = false;
      state.loanId = null;
      state.tokenId = null;
    },
  },
});

export const { setIsAddPrimaryPaymentMethod, setPrimaryPaymentMethodInfo, resetPrimaryPaymentMethodInfo } =
  primaryPaymentMethodInfoSlice.actions;

export default primaryPaymentMethodInfoSlice.reducer;
