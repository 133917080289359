import { isNull } from 'lodash';

import { INAPPBROWSER_SETTINGS, SCRATCHPAY_URL, isCordovaIOSApp } from 'utils/constants';

export const routes = {
  ROOT: '/',
  LOGIN: '/login',
  OTP_CONFIRMATION: '/otp-confirmation',
  HOME: '/home',
  USER_PROFILE: '/user-profile',
  CONTACT: '/contact',
  MY_LOANS: '/my-loans',
  MY_PAYMENT_METHODS: '/my-payment-methods',
  MY_PAYMENTS: '/my-payments',
  MY_SCHEDULED_PAYMENTS: '/my-schedules',
  LOAN_OVERVIEW: '/loan-overview',
  LOAN_OVERVIEW_BY_UNIQUE_ID: '/loans/:uniqueId',
  LOAN_OVERVIEW_BY_UNIQUE_ID_SCHEDULED: '/loans/:uniqueId/scheduled',
  LOAN_PAID_OFF: '/loan-paidoff',
  PAYMENT_DETAILS: '/payment-details',
  PAYMENT_METHOD_DETAILS: '/payment-method-details',
  PAYMENT_OVERVIEW: '/payment-overview',
  PAYMENT_METHOD_OVERVIEW: '/payment-method-overview',
  MY_DOCUMENTS: '/my-documents',
  EFT_AGREEMENT: '/eft-agreement',
  AUTOPAY_AUTH_TERMS: '/autopay-auth-terms',
  AUTOPAY_ACTIVATION: '/autopay-activation',
  SELECT_PAYMENT_METHOD: '/select-payment-method',
  NO_MATCH: '/404',
};

export const externalLinks: { [key: string]: string } = {
  HOME: SCRATCHPAY_URL,
  LEGAL: 'https://scratchpay.com/legal',
  HELP_CENTER: 'https://scratchplan.stonly.com/kb/en',
  PRACTICES_SEARCH: `${SCRATCHPAY_URL}/practices/search`,
  WRITE_REVIEW:
    ' https://www.trustpilot.com/evaluate/scratchpay.com?utm_campaign=Brand&utm_source=payoff-congrats&utm_medium=trustpilot',
};

export const lastLocationIsOneOfRoutes = (lastLocation: LastLocation, checkingRoutes: string[]): boolean => {
  if (isNull(lastLocation)) return false;
  return checkingRoutes.includes(lastLocation.pathname);
};

export const redirectToExternalUrl = (externalLink: string) => {
  if (isCordovaIOSApp) {
    window.cordova.InAppBrowser.open(
      externalLink,
      INAPPBROWSER_SETTINGS.OPEN_IN_SYSTEM_BROWSER,
      INAPPBROWSER_SETTINGS.SHOW_LOCATION_BAR,
    );
  } else {
    window.location.assign(externalLink);
  }
};
