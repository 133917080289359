import { useSelector } from 'react-redux';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { List, ListItem } from '@mui/material';

import { usePaymentMethodFetcher, usePaymentFetcher } from 'hooks';
import { selectPayment } from 'actions/payment';
import { routes } from 'utils/routeHelper';
import { formatAmount, formatDate } from 'utils/format';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import * as paymentSelectors from 'selectors/payment';

import { Header } from 'components/layouts';
import { Col, LoadingSpinner, Fab, IconPaymentMethod } from 'components/widgets';

import './MyPayments.scss';
import { useAppDispatch } from 'redux-hooks';
import { useEffect } from 'react';
import { addMavenWidget } from '../../utils/maven.ts';

export const MyPayments = () => {
  const location = useLocation();

  usePaymentFetcher();
  usePaymentMethodFetcher();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isFetchedPaymentMethods = useSelector(paymentMethodSelectors.isFetchedSelector);
  const isFetchedPayments = useSelector(paymentSelectors.isFetchedPaymentsSelector);
  const isFetchingPaymentMethods = useSelector(paymentMethodSelectors.isFetchingSelector);
  const isFetchingPayments = useSelector(paymentSelectors.isFetchingPaymentsSelector);
  const paymentMethods = useSelector(paymentMethodSelectors.paymentMethodsSelector);
  const payments = useSelector(paymentSelectors.paymentsSelector);

  const isFetched = isFetchedPaymentMethods && isFetchedPayments;
  const isFetching = isFetchingPaymentMethods || isFetchingPayments;
  const hasPaymentMethods = !!paymentMethods.length;
  const hasPayments = !!payments.length;

  useEffect(() => {
    addMavenWidget(location.pathname);
  }, [location.pathname]);

  const handleOnClickPaymentItem = (paymentId: number | string) => {
    dispatch(selectPayment(paymentId));
    navigate(routes.PAYMENT_OVERVIEW);
  };

  const handleMakePayment = () => navigate(routes.PAYMENT_DETAILS);

  const renderPaymentList = () => {
    if (!hasPayments) {
      return hasPaymentMethods ? (
        <div styleName="no-data-wrapper">
          You don't have any Payment yet. This is where you will see the list of all your payments.
        </div>
      ) : (
        <div styleName="no-data-wrapper">
          You don't have any Payment yet. To make and view payments here, first you must&nbsp;
          <Link to={routes.PAYMENT_METHOD_DETAILS}>add a Payment method</Link>.
        </div>
      );
    }

    return (
      <List styleName="list payments">
        {payments.map((payment: IPayment) => (
          <ListItem
            button
            key={payment.id}
            styleName={classNames('list-item payment', { 'has-refund': payment.isRefund })}
            onClick={() => handleOnClickPaymentItem(payment.id)}
          >
            <div styleName="date">{formatDate(payment.transactionDate)}</div>
            <div styleName="amount-wrapper">
              {payment.isRefund && <div styleName="tag-refund">Refund</div>}
              <IconPaymentMethod paymentType={payment.paymentMethod.type} />
              <div styleName="amount">{formatAmount(payment.totalAmount)}</div>
            </div>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Col xs={12} sm={6} md={6} styleName="wrapper">
      <Header />
      {isFetching && <LoadingSpinner />}
      {isFetched && renderPaymentList()}
      <Fab onClick={handleMakePayment} disabled={isFetching}>
        Make a payment
      </Fab>
    </Col>
  );
};

export default MyPayments;
