import { useCallback } from 'react';
import { IconMobilePhone } from 'assets/images';

import './CallButton.scss';
import { INAPPBROWSER_SETTINGS, isCordovaIOSApp } from 'utils/constants';
import { Box } from '@mui/material';
interface IProps {
  phoneNumber: string | undefined;
  title: string;
  align: string;
}

const CallButton = (props: IProps) => {
  const { align, phoneNumber, title } = props;
  const margin = align === 'left' ? '10px' : 'auto';

  const handleOnCallToPracticeClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  const ButtonContents = () => (
    <div
      styleName="block call-practice-border"
      style={{
        margin: margin,
      }}
    >
      <img src={IconMobilePhone} width="30" alt="mobile phone" />
      <div styleName="call-practice"> {title} </div>
    </div>
  );

  if (!phoneNumber) {
    return null;
  }

  return isCordovaIOSApp ? (
    <Box
      onClick={() =>
        window.cordova.InAppBrowser.open(
          `tel:${phoneNumber}`,
          INAPPBROWSER_SETTINGS.OPEN_IN_SYSTEM_BROWSER,
          INAPPBROWSER_SETTINGS.SHOW_LOCATION_BAR,
        )
      }
    >
      <ButtonContents />
    </Box>
  ) : (
    <a href={`tel:${phoneNumber}`} onClick={() => handleOnCallToPracticeClick}>
      <ButtonContents />
    </a>
  );
};

export default CallButton;
