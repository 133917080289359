import { useState, useEffect, ChangeEvent, ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, isUndefined } from 'lodash';
import numeral from 'numeral';

import { IMakePaymentData } from 'actions/payment';
import { parseToNumber, undoFormatAmount } from 'utils/format';
import {
  PaymentMethodDropdown,
  KeyboardWrapper,
  Col,
  PaymentFields,
  ActionButton,
  GifAnimationWrapper,
  NavigationLink,
} from 'components/widgets';
import { getConvenienceFee } from 'actions/convenienceFee';
import { borrowerSelector } from 'selectors/user';
import { isAutopayEnabledSelector } from 'selectors/loan';
import { DEVICE_CLASS } from 'utils/constants';
import { getPaymentInputSessionKey, getUseablePaymentMethodsByLoan } from 'utils/loan';
import { useClientStorage } from 'hooks';
import { Info } from '@mui/icons-material';

import './PaymentDetailsBody.scss';
import { routes } from 'utils/routeHelper';
import ConvenienceFeeModal from './ConvenienceFeeModal';
import usePayoffAmount from 'hooks/usePayoffAmount';
import { IField, ISubField } from './PaymentFields';
import { useAppDispatch } from 'redux-hooks';
import { selectorPaymentFieldIndex } from 'selectors/payment';

const getDefaultTokenId = (methods: IPaymentMethod[]): string => {
  if (!methods.length) return '';
  return methods[0].id;
};

export interface IPaymentDetailsBodyProps {
  isFetchingPaymentMethods: boolean;
  isFetchingMakePayment: boolean;
  selectedPayableLoan: ILoan;
  nextScheduledPaymentByLoanId: Dictionary<IScheduledPayment>;
  stripePaymentMethods: IPaymentMethod[];
  payixPaymentMethods: IPaymentMethod[];
  onLoadPaymentMethod: () => void;
  onConfirmAndPay: (data: IMakePaymentData, options: { isFullPayoffAmount: boolean }) => void;
  errorMessage: Nullable<string>;
}
type TFields = ComponentProps<typeof PaymentFields>['fields'];
type TSubFields = TFields[number]['subFields'];

export enum ActiveField {
  PaymentDue = 0,
  CustomAmount = 1,
  FullPaymentAmount = 2,
}

const ActiveFieldText = ['PAYMENT_DUE', 'CUSTOM_AMOUNT', 'FULL_PAYMENT'];

const PaymentDetailsBody = (props: IPaymentDetailsBodyProps) => {
  const {
    isFetchingPaymentMethods,
    isFetchingMakePayment,
    selectedPayableLoan,
    nextScheduledPaymentByLoanId,
    stripePaymentMethods,
    payixPaymentMethods,
    errorMessage,
  } = props;
  const { getItem: getCustomAmountStorageItem } = useClientStorage(
    'session',
    getPaymentInputSessionKey(selectedPayableLoan),
  );

  const borrower = useSelector(borrowerSelector);
  const selectedPaymentFieldIndex = useSelector(selectorPaymentFieldIndex);
  const isAutopayEnabled = useSelector(isAutopayEnabledSelector(selectedPayableLoan.id));

  const [errorMsg, setErrorMsg] = useState('');
  const [showConvenienceFeeModal, setShowConvenienceFeeModal] = useState(false);
  const {
    isPayoffAmount,
    paymentAmount: selectedLoanPaymentAmount,
    isFetchingConvenienceFee,
    convenienceFeeValue,
    lateFeeValue,
    additionalFees,
    actualNextInstallment,
    totalNextInstallment,
  } = usePayoffAmount();
  const initialUseablePaymentMethods = getUseablePaymentMethodsByLoan({
    loan: selectedPayableLoan,
    stripePaymentMethods,
    payixPaymentMethods,
  });

  const actualNextInstallmentAsStr = actualNextInstallment.toString();
  const [paymentDetailsData, setPaymentDetailsData] = useState<{
    tokenId: string;
    amount: string;
    loanId: string;
    channel: string;
  }>({
    tokenId: getDefaultTokenId(initialUseablePaymentMethods),
    amount: actualNextInstallmentAsStr,
    loanId: selectedPayableLoan.id,
    channel: DEVICE_CLASS,
  });
  const dispatch = useAppDispatch();
  const currentUseablePaymentMethods = getUseablePaymentMethodsByLoan({
    loan: selectedPayableLoan,
    stripePaymentMethods,
    payixPaymentMethods,
  });

  const scratchBorrowerId = borrower && borrower.scratchBorrowerId ? borrower.scratchBorrowerId : '';

  useEffect(() => {
    if (!paymentDetailsData.tokenId) {
      setPaymentDetailsData((paymentDetailsData) => ({
        ...paymentDetailsData,
        tokenId: getDefaultTokenId(currentUseablePaymentMethods),
      }));
    }
  }, [paymentDetailsData.tokenId, currentUseablePaymentMethods]);

  useEffect(() => {
    if (paymentDetailsData.tokenId !== '' && selectedPayableLoan.id !== '') {
      const payload = {
        loanId: selectedPayableLoan.id,
        scratchBorrowerId,
        tokenId: paymentDetailsData.tokenId,
        isScraLoan: selectedPayableLoan.isSCRALoan || false,
      };
      dispatch(
        getConvenienceFee({
          data: payload,
        }),
      );
    }
  }, [selectedPayableLoan.id, scratchBorrowerId, paymentDetailsData.tokenId, selectedPayableLoan.isSCRALoan, dispatch]);

  const payoffBalance = selectedPayableLoan.currentPayoffBalance;
  const selectedAmountForPaymentAsStr = paymentDetailsData.amount;
  const selectedAmountForPaymentAsNum = parseToNumber(selectedAmountForPaymentAsStr);
  const customPaymentInputByUser = parseToNumber(String(getCustomAmountStorageItem())) || 0;
  // actual amount entered by user to pay off is less than shown if there are additional fees
  const actualCustomPaymentToPlan = numeral(customPaymentInputByUser).subtract(additionalFees).value() || 0;
  const currencySymbol = '$';

  useEffect(() => {
    let paymentAmount: string;
    const payoffAmount = payoffBalance ? payoffBalance.toString() : '';
    switch (selectedPaymentFieldIndex) {
      case ActiveField.CustomAmount:
        paymentAmount = customPaymentInputByUser.toString();
        break;
      case ActiveField.FullPaymentAmount:
        paymentAmount = payoffAmount;
        break;
      case ActiveField.PaymentDue:
        if (isPayoffAmount) {
          paymentAmount = payoffAmount;
          break;
        }
        paymentAmount = actualNextInstallmentAsStr;
        break;
    }

    setPaymentDetailsData((paymentDetailsData) => ({ ...paymentDetailsData, amount: paymentAmount }));
  }, [selectedPaymentFieldIndex, actualNextInstallmentAsStr, customPaymentInputByUser, isPayoffAmount, payoffBalance]);

  useEffect(() => {
    if (additionalFees && selectedAmountForPaymentAsNum <= additionalFees) {
      setErrorMsg(`Payment must be greater than ${currencySymbol}${additionalFees.toFixed(2)}`);
    } else {
      setErrorMsg('');
    }
  }, [selectedAmountForPaymentAsNum, payoffBalance, additionalFees]);

  if (!payoffBalance) return null;

  const handleOnChangePaymentMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const nextTokenId = event.target.value;
    if (isUndefined(nextTokenId)) return;

    setPaymentDetailsData((paymentDetailsData) => ({
      ...paymentDetailsData,
      tokenId: nextTokenId,
    }));
  };

  const handleOnChangePaymentAmount = (amount: string) => {
    setPaymentDetailsData((paymentDetailsData) => ({
      ...paymentDetailsData,
      amount,
    }));
  };

  const handleOnConfirmAndPay = () => {
    let paymentAmount: number | string = actualNextInstallmentAsStr;
    const payoffPrincipalAndInterest: number = lateFeeValue ? payoffBalance - lateFeeValue : payoffBalance;
    switch (selectedPaymentFieldIndex) {
      // this payment doesn't add neither late fees nor convenience fee
      case ActiveField.PaymentDue:
        if (isPayoffAmount) {
          paymentAmount = String(payoffPrincipalAndInterest);
          break;
        }
        paymentAmount = String(selectedLoanPaymentAmount);
        break;
      // this payment is the amount the user enters
      // additional fees are paid (first) from it
      case ActiveField.CustomAmount:
        if (selectedAmountForPaymentAsNum === payoffBalance) {
          paymentAmount = String(payoffPrincipalAndInterest);
        } else {
          paymentAmount = String(actualCustomPaymentToPlan);
        }
        break;
      // this is the payoff balance retrieved from lms
      case ActiveField.FullPaymentAmount:
        paymentAmount = String(payoffPrincipalAndInterest);
        break;
    }
    paymentAmount = parseFloat(undoFormatAmount(paymentAmount));
    const isFullPayoffAmount = paymentAmount === payoffPrincipalAndInterest;
    if (paymentAmount <= 0) return;
    return props.onConfirmAndPay(
      {
        ...paymentDetailsData,
        amount: paymentAmount,
        waiveConvenienceFee: isFullPayoffAmount,
        isSACPayoffPayment: isFullPayoffAmount && selectedPayableLoan.isStillInPromotionalPeriod,
        selectedPaymentOption: ActiveFieldText[selectedPaymentFieldIndex] || 'UNKNOWN',
      },
      { isFullPayoffAmount },
    );
  };

  const hasNextScheduledPayment = () => {
    const nextScheduledPayment = nextScheduledPaymentByLoanId[paymentDetailsData.loanId];
    return !!nextScheduledPayment && !nextScheduledPayment.isReadonly;
  };

  const isCustomFieldSelected = selectedPaymentFieldIndex === ActiveField.CustomAmount;
  const hasPaymentError = () => {
    if (!paymentDetailsData.loanId || !selectedAmountForPaymentAsStr) return false;
    if (!hasNextScheduledPayment()) return false;
    if (isCustomFieldSelected) {
      if (!!errorMsg) return true;
      return false;
    }
  };
  const hasInvalidPaymentValue =
    !selectedAmountForPaymentAsNum || (isCustomFieldSelected && actualCustomPaymentToPlan <= 0);
  const isConfirmAndPayButtonDisabled =
    isFetchingMakePayment || !paymentDetailsData.tokenId || hasInvalidPaymentValue || hasPaymentError();

  const subFields: TSubFields = !!additionalFees
    ? [
        {
          inputTitle: 'Late Fee',
          amount: lateFeeValue,
        },
        {
          inputTitle: 'Convenience Fee',
          amount: convenienceFeeValue,
          icon: <Info onClick={() => setShowConvenienceFeeModal(true)} fontSize="inherit" />,
        },
      ]
    : [];
  const inputTitlePlanPayment = 'Scratch Plan Payment';

  const paymentDueField: IField & { subFields?: ISubField[] } = isPayoffAmount
    ? {
        inputTitle: 'Payment due',
        amount: payoffBalance,
      }
    : {
        inputTitle: 'Payment due',
        amount: totalNextInstallment,
        subFields: [
          {
            inputTitle: inputTitlePlanPayment,
            amount: selectedLoanPaymentAmount,
          },
          ...subFields,
        ],
      };
  const paymentFields: TFields = [
    paymentDueField,
    {
      inputTitle: 'Other amount',
      amount: customPaymentInputByUser,
      subFields: [
        {
          inputTitle: inputTitlePlanPayment,
          amount: actualCustomPaymentToPlan,
        },
        ...subFields,
      ],
    },
    {
      inputTitle: 'Pay off plan',
      amount: payoffBalance,
    },
  ];
  const shouldDisablePaymentBtn = isConfirmAndPayButtonDisabled || isFetchingConvenienceFee;
  const isFetching = isFetchingConvenienceFee || isFetchingMakePayment;
  const selectedPaymentMethod = currentUseablePaymentMethods.find((method) => method.id === paymentDetailsData.tokenId);
  const selectedPaymentMethodType = (selectedPaymentMethod && selectedPaymentMethod.paymentType) || '';

  return (
    <KeyboardWrapper>
      <div styleName="wrapper">
        <p styleName="card-title">Payment Type:</p>
        <Col styleName="make-payment">
          <PaymentFields
            fields={paymentFields}
            activeFieldIndex={selectedPaymentFieldIndex}
            value={selectedAmountForPaymentAsStr}
            currencySymbol={currencySymbol}
            onChange={handleOnChangePaymentAmount}
            error={hasPaymentError()}
            errorMessage={errorMsg}
            payoffBalance={payoffBalance}
          />
          <div className="fixed-bottom" styleName="error-message">
            {errorMessage}
          </div>
          <ConvenienceFeeModal
            convenienceFeeAmount={convenienceFeeValue}
            show={showConvenienceFeeModal}
            setShow={setShowConvenienceFeeModal}
            isAutopayEnabled={isAutopayEnabled}
          />
        </Col>
        <p styleName="card-title">Payment Details:</p>
        <Col styleName="payment-method">
          <PaymentMethodDropdown
            label={null}
            disabled={isFetchingMakePayment}
            isFetching={isFetchingPaymentMethods}
            paymentMethods={currentUseablePaymentMethods}
            value={paymentDetailsData.tokenId}
            onBeforeOpen={props.onLoadPaymentMethod}
            onChange={handleOnChangePaymentMethod}
          />
        </Col>
        <div styleName="agreement-link">
          <NavigationLink
            text="Electronic Funds Transfer Agreement"
            to={{
              pathname: routes.EFT_AGREEMENT,
            }}
            state={{
              borrower,
              paymentDetails: {
                amount: selectedAmountForPaymentAsNum,
                paymentMethodType: selectedPaymentMethodType.toLowerCase(),
                paymentMethodLast4: (selectedPaymentMethod && selectedPaymentMethod.last4) || '',
              },
            }}
          />
        </div>
        <div styleName="payment-btn">
          <ActionButton
            id="payment-btn"
            customVariant="standard"
            disabled={shouldDisablePaymentBtn}
            onClick={handleOnConfirmAndPay}
            type="submit"
          >
            {isFetchingMakePayment ? 'Processing...' : 'Make Payment'}
          </ActionButton>
        </div>
      </div>
      {isFetching && <GifAnimationWrapper />}
    </KeyboardWrapper>
  );
};

export default PaymentDetailsBody;
