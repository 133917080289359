import moment, { Moment } from 'moment';
import numeral from 'numeral';
import { capitalize, isNil, sum } from 'lodash';

import {
  CURRENCY_FORMAT,
  PAYMENT_METHOD_TYPES,
  DEFAULT_VALUE_UNAVAILABLE_FEE,
  DEFAULT_CURRENCY_SYMBOL,
  AREA_CODE_US_CANADA,
  SUPPORT_CONTACT,
} from 'utils/constants';

export const DATE_FORMATS = {
  UTC_DATE: 'YYYY-MM-DD',
  UTC_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  YYYY_DD_MM: 'YYYY/DD/MM',
  MM_DD_YYYY: 'MM/DD/YYYY',
  MM_DD_YY: 'MM/DD/YY',
  MM_DD_YYYY_HH_MM: 'MM/DD/YYYY - HH:mm',
  MM_DD_YYYY_2: 'MM-DD-YYYY',
  MM_YYYY_1: 'MM/YYYY',
  MM_YYYY_2: 'MM-YYYY',
  MM_YY: 'MM/YY',
  HH_MM_A: 'HH:mmA',
  MMM_DD_YYYY: 'MMM D, YYYY',
};

export const DEFAULT_MIN_DATE = '1900-01-01';

interface IFormatDateOptions {
  outputFormat?: string;
}

const HIDDEN_DIGIT_SYMBOL = '•';

export const parseToNumber = (value: string): number => numeral(value).value() || 0;

export const limitTwoDecimalPlaces = (value: string) => {
  return value.indexOf('.') >= 0 ? value.slice(0, value.indexOf('.') + 3) : value;
};

export const toCurrencyFormat = (string: string): string => {
  if (+string < 0) return '0';
  const splitStr = string.split('.');
  let integerStr = splitStr[0];
  const decimalStr = splitStr[1];

  let temporaryStr = '';
  const strArr: string[] = [];
  integerStr
    .split('')
    .reverse()
    .forEach((s, i) => {
      temporaryStr = s + temporaryStr;
      if ((i + 1) % 3 === 0) {
        strArr.push(temporaryStr);
        temporaryStr = '';
      }
      if (temporaryStr && i === integerStr.length - 1) {
        strArr.push(temporaryStr);
      }
    });
  integerStr = strArr.reverse().join(',');

  const shouldUseDecimal = splitStr.length > 1 && (strArr.length > 0 || +decimalStr > 0);
  return shouldUseDecimal ? `${integerStr}.${decimalStr}` : integerStr;
};

export const removeLeadingZeros = (value: string) => value.replace(/^0+/, '');

export const formatDate = (date: string | Moment | Date, options: IFormatDateOptions = {}): string => {
  const { outputFormat = DATE_FORMATS.MMM_DD_YYYY } = options;
  return moment(date).format(outputFormat);
};

export const formatHiddenDigitsWithLast4 = (paymentType: string, last4: string): string => {
  if ([PAYMENT_METHOD_TYPES.ACH, PAYMENT_METHOD_TYPES.PAD].includes(paymentType)) {
    return `${HIDDEN_DIGIT_SYMBOL.repeat(5)} ${last4}`;
  }
  const fourSymbols = HIDDEN_DIGIT_SYMBOL.repeat(4);
  return `${fourSymbols} ${fourSymbols} ${fourSymbols} ${last4}`;
};

export interface IPhoneNumber {
  areaCode: string;
  phoneNumber: string;
}

export const formatPhoneNumber = (
  areaCode: string,
  phoneNumber: string,
  options?: {
    separator: string;
  },
): string => {
  const { separator = '-' } = options || {};
  const formattedPhoneNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/g, `($1) $2${separator}$3`);
  return `${areaCode} ${formattedPhoneNumber}`;
};

export const makeRawPhoneNumber = (phoneNumber: string): string => phoneNumber.replace(/[()\s-]/g, '');

interface IFormatAmountOptions {
  currencySymbol: string;
}

const DEFAULT_FORMAT_AMOUNT_OPTIONS: IFormatAmountOptions = {
  currencySymbol: DEFAULT_CURRENCY_SYMBOL,
};

export const formatAmount = (
  value: string | number,
  options: IFormatAmountOptions = DEFAULT_FORMAT_AMOUNT_OPTIONS,
): string => {
  if (isNil(value) || value === '') return '';
  const { currencySymbol } = options;
  const formattedAmount = numeral(value).format(CURRENCY_FORMAT, Math.floor);
  return `${currencySymbol}${formattedAmount}`;
};

export const undoFormatAmount = (
  value: string,
  options: IFormatAmountOptions = DEFAULT_FORMAT_AMOUNT_OPTIONS,
): string => {
  const { currencySymbol } = options;
  return value.replace(currencySymbol, '').replace(/,/g, '');
};

export const formatTotalAmount = (
  amounts: Undefinable<number>[] = [],
  options: IFormatAmountOptions = DEFAULT_FORMAT_AMOUNT_OPTIONS,
): string => {
  if (!amounts.length) return '';
  const summableAmounts = amounts.filter((amount) => !isNil(amount));
  if (!summableAmounts.length) return '';
  const totalAmount = sum(summableAmounts);
  return formatAmount(totalAmount, options);
};

export const getAmountInDollars = (value: number, unit: number): number => value / unit;

export const formatFeeAmount = (fee: Undefinable<number>): Undefinable<string> => {
  if (isNil(fee)) return DEFAULT_VALUE_UNAVAILABLE_FEE;
  return fee > 0 ? formatAmount(fee) : '';
};

export const formatExpiryDateFromPayix = (expiryDate: string): string => {
  return expiryDate.replace(/(\d{2})\/(\d{4})/, '$2-$1-01');
};

export const formatExpiryDateFromStripe = (expYear: number, expMonth: number): string => {
  return `${expYear}-${`0${expMonth}`.slice(-2)}-01`;
};

export const FormattedSupportContact = {
  PHONE_NUMBER: formatPhoneNumber(AREA_CODE_US_CANADA, SUPPORT_CONTACT.PHONE_NUMBER),
  PHONE_NUMBER_REF: `tel:${AREA_CODE_US_CANADA}${SUPPORT_CONTACT.PHONE_NUMBER}`,
  FAX_NUMBER: formatPhoneNumber(AREA_CODE_US_CANADA, SUPPORT_CONTACT.FAX_NUMBER),
  FAX_NUMBER_REF: `fax:${AREA_CODE_US_CANADA}${SUPPORT_CONTACT.FAX_NUMBER}`,
  EMAIL_REF: `mailto:${SUPPORT_CONTACT.EMAIL}`,
};

export const getBorrowerFullName = (borrower: { firstName: string; lastName: string }) => {
  return borrower
    ? [borrower.firstName, borrower.lastName]
        .filter((name) => name)
        .map(capitalize)
        .join(' ')
    : '';
};
