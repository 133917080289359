import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import { routes } from 'utils/routeHelper';

import './CancelLink.scss';

type TProps = Omit<ComponentProps<typeof Link>, 'to'>;

const CancelLink = (props: TProps) => {
  return (
    <div styleName="wrapper">
      <Link to={routes.HOME} {...props}>
        Cancel
      </Link>
    </div>
  );
};

export default CancelLink;
