import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { routes } from 'utils/routeHelper';

export const initialState: IAppState = {
  returnUrl: routes.HOME,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReturnUrl: (state, action: PayloadAction<string>) => {
      state.returnUrl = action.payload;
    },
  },
});

export const { setReturnUrl } = appSlice.actions;

export default appSlice.reducer;
