import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';

import { formatDate } from 'utils/format';
import { NODE_ID_RECEIPT_TEMPLATE } from 'utils/payment';
import { borrowerSelector } from 'selectors/user';

import { LogoScratchpayTextWhite } from 'assets/images';
import PaymentOverviewBody from './PaymentOverviewBody';

import './ReceiptTemplate.scss';

export interface IReceiptTemplateProps {
  isScheduledPayment: boolean;
  payment: IPayment | IScheduledPayment;
}

const ReceiptTemplate = (props: IReceiptTemplateProps) => {
  const { isScheduledPayment, payment } = props;
  const borrower = useSelector(borrowerSelector);

  const paymentDate =
    isScheduledPayment && (payment as IScheduledPayment).transaction
      ? (payment as IScheduledPayment).transaction!.paidAt
      : (payment as IPayment).transactionDate;

  const fullName = borrower
    ? [borrower.firstName, borrower.lastName]
        .filter((name) => name)
        .map(capitalize)
        .join(' ')
    : '';

  return (
    <div styleName="wrapper" id={NODE_ID_RECEIPT_TEMPLATE} style={{ display: 'none' }}>
      <div styleName="header">
        <div styleName="left-block">
          <span>
            Hello <b styleName="full-name">{fullName}</b>,
          </span>
          <span>Your payment is complete</span>
        </div>

        {paymentDate && (
          <div styleName="right-block">
            <span>{formatDate(paymentDate)}</span>
          </div>
        )}
      </div>

      <div styleName="content">
        <PaymentOverviewBody isRenderedForReceiptTemplate isScheduledPayment={isScheduledPayment} payment={payment} />
      </div>

      <div styleName="footer">
        <div styleName="left-block">
          <span>Paid to:</span>
          <img src={LogoScratchpayTextWhite} alt="scratchpay" />
        </div>

        <div styleName="right-block">
          <span>https://scratchpay.com</span>
          <span>225 S Lake Ave #250</span>
          <span>Los Angeles CA, 91101</span>
        </div>
      </div>
    </div>
  );
};

export default ReceiptTemplate;
