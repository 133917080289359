import { createTheme } from '@mui/material/styles';
import {
  responsiveMuiFab,
  responsiveMuiButton,
  responsiveMuiAppBar,
  responsiveMuiDialogFullScreen,
  responsiveMuiSnackbarContentRoot,
  responsiveMuiSnackbarContentMessage,
} from './responsive';
import colors from '../styles/colors.module.scss';
import variables from '../styles/variables.module.scss';

const theme = createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          boxShadow: colors.greyBoxShadow,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: colors.white,
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.black,
          '&$.Mui-focused': {
            color: colors.secondary,
          },
        },
        filled: {
          color: colors.grey500,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&$.Mui-focused $notchedOutline': {
            borderColor: colors.secondary,
          },
          '&.Mui-focused fieldset': {
            color: colors.secondary,
          },
        },
        notchedOutline: {
          borderColor: colors.grey500,
        },
      },
    },

    MuiFab: {
      styleOverrides: {
        extended: {
          backgroundColor: colors.secondary,
          color: colors.primary,
          fontSize: 14,
          fontWeight: 700,
          textTransform: 'none',
          transition: '0.3s ease',
          ...responsiveMuiFab,

          '& > *': {
            fontFamily: `${variables.fontBalooBhai} !important`,
          },

          '&:disabled': {
            backgroundColor: colors.grey300,
            color: colors.white,
          },

          '&:hover': {
            backgroundColor: colors.secondary,
            color: colors.primary,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: colors.secondary,
          color: colors.primary,
          fontSize: 14,
          fontWeight: 700,
          lineHeight: '46px',
          width: 200,
          padding: 0,
          boxShadow: 'none',
          textTransform: 'none',
          transition: '0.3s ease',
          ...responsiveMuiButton,

          '& > *': {
            fontFamily: `${variables.fontBalooBhai} !important`,
          },

          '&:disabled': {
            backgroundColor: colors.grey300,
            color: colors.white,
          },

          '&:hover': {
            backgroundColor: colors.secondary,
            color: colors.primary,
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: colors.white,
          borderRadius: 8,
          boxShadow: colors.greyBoxShadow,
          color: colors.black100,
          margin: 0,
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        positionFixed: {
          ...responsiveMuiAppBar,
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          height: 'auto',
          borderRadius: 4,
          ...responsiveMuiDialogFullScreen,
        },
      },
    },

    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff !important',
          minHeight: 80,
          borderRadius: 20,
          boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
          flexGrow: 'unset',
          padding: '0 16px !important',
          ...responsiveMuiSnackbarContentRoot,
        },
        message: {
          ...responsiveMuiSnackbarContentMessage,
        },
      },
    },

    MuiGrid: {
      styleOverrides: {
        item: ({ ownerState }: { ownerState: any }) => ({
          ...(ownerState.alignItems === 'center' && {
            alignItems: 'center',
          }),
        }),
      },
    },
  },
});

export const styleColors = colors;
export default theme;
