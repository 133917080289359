import { BaseAction, createAction, Action } from "redux-actions";

export const actionTypes = {
    GET_DOCUMENTS: 'GET_DOCUMENTS',
    GET_DOCUMENTS_REQUEST: 'GET_DOCUMENTS_REQUEST',
    GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
    GET_DOCUMENTS_ERROR: 'GET_DOCUMENTS_ERROR',
    DOWNLOAD_DOCUMENT: 'DOWNLOAD_DOCUMENT',
};

export const getDocuments: () => BaseAction = createAction(actionTypes.GET_DOCUMENTS);

export const getDocumentsRequest: () => BaseAction = createAction(actionTypes.GET_DOCUMENTS_REQUEST);

export const getDocumentsSuccess: (receipts: ILoanDocuments[]) => Action<ILoanDocuments[]> = createAction(
    actionTypes.GET_DOCUMENTS_SUCCESS
);

export const getDocumentsError: () => Action<void> = createAction(actionTypes.GET_DOCUMENTS_ERROR);

export const downloadDocument: (payload: IDownloadDocumentPayload) => Action<void> = createAction(actionTypes.DOWNLOAD_DOCUMENT);

export interface IDownloadDocumentPayload {
    data: ILoanDocument;
    onSuccess: (file: any, data: ILoanDocument) => void;
    onError?: (errorMessage: string, data: ILoanDocument) => void;
};
