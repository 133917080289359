import classNames from 'classnames';
import { TextField } from '@mui/material';
import { OutlinedTextFieldProps } from '@mui/material/TextField';
import { SelectProps } from '@mui/material/Select';

import { ExpandMore, ExpandLess } from '@mui/icons-material';

import './SelectField.scss';

const MAX_DROPDOWN_HEIGHT = 450;

type Props = Partial<OutlinedTextFieldProps> & {
  SelectProps: SelectProps;
  extraProps: {
    isOpen: boolean;
  };
};

const SelectField = (props: Props) => {
  const {
    disabled,
    SelectProps,
    extraProps: { isOpen },
    ...restProps
  } = props;

  const mergedProps = {
    disabled,
    ...restProps,
  };

  return (
    <TextField
      {...mergedProps}
      styleName={classNames('wrapper', { disabled })}
      variant="outlined"
      select
      SelectProps={{
        ...SelectProps,
        IconComponent: isOpen ? ExpandLess : ExpandMore,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: MAX_DROPDOWN_HEIGHT,
            },
          },
        },
      }}
    />
  );
};

export default SelectField;
