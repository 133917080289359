import { toggleAutopay } from 'actions/loan';
import { useAppDispatch } from 'redux-hooks';

import SetPrimaryPaymentMethodDialog, { Props } from './SetPrimaryPaymentMethodDialog';

const EnableAutopayDialog = (props: Props) => {
  const { loanId } = props;
  const dispatch = useAppDispatch();

  const handleOnSetPrimaryPaymentMethod = (tokenId: string) => {
    dispatch(
      toggleAutopay({
        data: {
          loanId,
          tokenId,
          isEnabled: true,
        },
      }),
    );
  };

  return <SetPrimaryPaymentMethodDialog {...props} onSetPrimaryPaymentMethod={handleOnSetPrimaryPaymentMethod} />;
};

export default EnableAutopayDialog;
