import { useState, useEffect, useRef, FormEvent } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { routes } from 'utils/routeHelper';
import { REGEXES } from 'utils/validate';
import { makeRawPhoneNumber, formatPhoneNumber } from 'utils/format';
import {
  isCordovaApp,
  SCRATCHPAY_URL,
  AUTH_METHOD_SMS,
  AUTH_METHOD_VOICE_CALL,
  SUPPORT_CONTACT,
} from 'utils/constants';
import { showNotification } from 'utils/notification';
import firebaseClient from 'utils/firebase';
import {
  getAuth,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber,
  signInWithCustomToken,
  signOut,
  User,
  UserCredential,
} from 'firebase/auth';

import { Col, Fab, TextInput, KeyboardWrapper, LoadingSpinner } from 'components/widgets';
import { Header } from 'components/layouts';
import { IconScratchpayHeart } from 'assets/icons';
import { LogoScratchpayTextOnlyWhite } from 'assets/images';
import { signInSuccessUrlSelector } from 'selectors/user';
import { setSignInSuccessUrl } from 'actions/user';
import { generateAuthToken, sendVoiceOTP, verifyVoiceOTP } from 'apis';
import './OTPConfirmation.scss';
import { FirebaseError } from 'firebase/app';
import { captureException } from 'utils/sentry';
import { useAppDispatch } from 'redux-hooks';

const RECAPTCHA_CONTAINER_ID = 'recaptcha-container';
const OTP_TIMEOUT = 60; // seconds
const HIDE_RESEND_BUTTON_TIMEOUT = OTP_TIMEOUT * 1000; // 60 seconds
const ERROR_TYPES = {
  EXCEED_ATTEMPTS: 'EXCEED_ATTEMPTS',
  OTP_EXPIRED: 'OTP_EXPIRED',
  INVALID_PHONE: 'INVALID_PHONE',
  INVALID_OTP: 'INVALID_OTP',
  INVALID_OTP_ANDROID: 'Invalid verification code',
  RECAPTCHA_EXPIRED: 'RECAPTCHA_EXPIRED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  VERIFICATION_ID_MISSING: 'VERIFICATION_ID_MISSING',
};

type OTPError = {
  message?: string;
  type?: string;
} | null;

interface IResendButtonStates {
  isDisplayed: boolean;
  isDisabled: boolean;
}
const resendButtonsActive: IResendButtonStates = {
  isDisplayed: true,
  isDisabled: false,
};
const resendButtonsDisabled: IResendButtonStates = {
  isDisplayed: true,
  isDisabled: true,
};

type PhoneNumber = {
  withAreaCode: string;
  forDialing: string;
  forDisplay: string;
};

const isTestingEnv = process.env.DEPLOYMENT_ENV === 'staging';

const getPhoneNumber = (locationState?: PhoneNumberState) => {
  if (!locationState || !locationState.areaCode || !locationState.phoneNumber) {
    return null;
  }

  const areaCode = locationState.areaCode;
  const phoneNumber = locationState.phoneNumber;
  const rawPhoneNumber = makeRawPhoneNumber(phoneNumber);
  return {
    withAreaCode: `${areaCode}${rawPhoneNumber}`,
    forDialing: `1${rawPhoneNumber}`,
    forDisplay: formatPhoneNumber(areaCode, phoneNumber),
  };
};
const OTPConfirmation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const timeoutIdRef = useRef<any>();
  const auth = getAuth(firebaseClient);

  const passedState = location.state;
  const phoneNumber: PhoneNumber | null = getPhoneNumber(passedState);

  if (isTestingEnv) {
    auth.settings.appVerificationDisabledForTesting = true;
  }

  const recaptchaVerifier = useRef<RecaptchaVerifier>();
  const signInSuccessUrl = useSelector(signInSuccessUrlSelector);
  const [authMethod, setAuthMethod] = useState<string>(AUTH_METHOD_SMS);

  const [otp, setOTP] = useState<string>('');
  const [resendButtonStates, setResendButtonStates] = useState<IResendButtonStates>(resendButtonsActive);
  const [error, setError] = useState<OTPError>(null);
  const [verificationId, setVerificationId] = useState<Nullable<string>>(null);
  const [isSendingOTP, setIsSendingOTP] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isLoadingRecaptcha, setIsLoadingRecaptcha] = useState<boolean>(false);

  const maxOTPLength = 6;
  const isConfirmOTPButtonDisabled = isSendingOTP || isConfirming || otp.length !== maxOTPLength;
  useEffect(() => {
    if (phoneNumber) {
      if (!isCordovaApp) {
        setIsLoadingRecaptcha(true);
        recaptchaVerifier.current = new RecaptchaVerifier(auth, RECAPTCHA_CONTAINER_ID, {
          size: 'invisible',
          callback: () => setIsLoadingRecaptcha(false),
          'expired-callback': () => setError({ type: ERROR_TYPES.RECAPTCHA_EXPIRED }),
        });
      }
      handleOnResend(AUTH_METHOD_SMS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!phoneNumber) {
    return <Navigate to={routes.LOGIN} replace />;
  }
  const sendOTPViaSMS = async () => {
    try {
      if (isCordovaApp) {
        const options = {
          // Following line can be uncommented to test Android auto-validation
          // ...(phoneNumber.forDialing === '16505552222' ? { fakeVerificationCode: '222222' } : undefined),
          requireSmsValidation: false,
          timeOutDuration: OTP_TIMEOUT,
        };

        const onError = (error: string) => {
          handleErrorMessage(error);
          setIsSendingOTP(false);
        };

        const onOTPSent = (credential: CordovaCredentials) => {
          const onAutoSignInAndroid = () => {
            const onLoggedInUser = async (user: User) => {
              const { token } = await generateAuthToken(phoneNumber.forDialing);
              await signInWithCustomToken(auth, token!);
              completeSignIn(user);
              setIsSendingOTP(false);
            };

            window.FirebasePlugin.getCurrentUser(onLoggedInUser, onError);
          };

          if (credential.instantVerification) {
            // Skip OTP collection as Android device used auto-retrieval/instant verification
            window.FirebasePlugin.signInWithCredential(credential, onAutoSignInAndroid, onError);
          } else {
            setVerificationId(credential.verificationId);
            setIsSendingOTP(false);
          }
        };

        window.FirebasePlugin.verifyPhoneNumber(onOTPSent, onError, phoneNumber.withAreaCode, options);
      } else if (recaptchaVerifier.current) {
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          phoneNumber.withAreaCode,
          recaptchaVerifier.current,
        );
        setVerificationId(confirmationResult.verificationId);
        setIsSendingOTP(false);
      }
    } catch (error) {
      handleErrorMessage(error);
      setIsSendingOTP(false);
    }
  };

  const handleOnResend = async (authMethod: string) => {
    setAuthMethod(authMethod);

    setIsSendingOTP(true);
    setError(null);
    setOTP('');
    setVerificationId('');

    setResendButtonStates(resendButtonsDisabled);
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      setResendButtonStates(resendButtonsActive);
    }, HIDE_RESEND_BUTTON_TIMEOUT);

    if (authMethod === AUTH_METHOD_SMS) {
      sendOTPViaSMS();
    } else {
      try {
        await sendVoiceOTP(phoneNumber.forDialing);
        setIsSendingOTP(false);
      } catch (error) {
        handleErrorMessage(error);
        setIsSendingOTP(false);
      }
    }
  };

  const handleOnChangeOTP = (updatedOTP: string) => {
    if (REGEXES.NUMBER_ONLY.test(updatedOTP)) {
      setError(null);
      setOTP(updatedOTP);
    }
  };

  const handleOnFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isConfirmOTPButtonDisabled) {
      return;
    }
    handleOnConfirmOTP();
  };

  const completeSignIn = (user: User | UserCredential) => {
    let currentUser = user || getAuth(firebaseClient).currentUser;
    if (currentUser) {
      const resetSignInSuccessUrl = () => dispatch(setSignInSuccessUrl(''));

      clearTimeout(timeoutIdRef.current);
      if (signInSuccessUrl) {
        navigate(signInSuccessUrl, { replace: true });
        resetSignInSuccessUrl();
      } else {
        navigate(routes.HOME, { replace: true });
      }
    } else {
      handleErrorMessage('login failed');
    }
  };

  const handleOnConfirmOTP = async () => {
    setIsConfirming(true);
    let user;
    try {
      if (authMethod === AUTH_METHOD_SMS) {
        if (verificationId) {
          const phoneCredential = PhoneAuthProvider.credential(verificationId, otp);
          user = await signInWithCredential(auth, phoneCredential);
        } else {
          handleErrorMessage(ERROR_TYPES.VERIFICATION_ID_MISSING);
          return;
        }
      } else {
        const { token } = await verifyVoiceOTP(phoneNumber.forDialing, otp);
        user = await signInWithCustomToken(auth, token!);
      }
      completeSignIn(user);
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const handleErrorMessage = async (error: any) => {
    showNotification("We're unable to complete your login", { variant: 'error' });
    const { currentUser } = auth;
    if (currentUser === null) {
      await signOut(auth);
    }
    setIsConfirming(false);
    if (error instanceof FirebaseError) {
      if (error.code === 'auth/invalid-verification-code') {
        setError({ type: ERROR_TYPES.INVALID_OTP });
      } else if (error.code === 'auth/too-many-requests') {
        setError({ type: ERROR_TYPES.EXCEED_ATTEMPTS });
      } else if (error.code === 'auth/invalid-phone-number' || error.code === 'auth/user-not-found') {
        setError({ type: ERROR_TYPES.INVALID_PHONE });
      } else if (error.code === 'auth/session_cookie_expired' || error.code === 'auth/code-expired') {
        setError({ type: ERROR_TYPES.OTP_EXPIRED });
      } else {
        setError({ message: error.code });
        captureException(error);
      }
    } else if (error.isAxiosError) {
      if (error.response) {
        setError({ message: error.response.data.message });
      } else {
        setError({ message: error.message });
      }
    } else {
      if (error === ERROR_TYPES.INVALID_OTP_ANDROID) {
        setError({ type: ERROR_TYPES.UNKNOWN_ERROR });
      } else if (error.code === '17010') {
        setError({ type: ERROR_TYPES.EXCEED_ATTEMPTS });
      } else if (error === ERROR_TYPES.VERIFICATION_ID_MISSING) {
        setError({ type: ERROR_TYPES.VERIFICATION_ID_MISSING });
      } else {
        setError({ message: error });
      }
      captureException(error);
    }
  };

  const handleOnBackToLoginPage = () => {
    navigate(routes.LOGIN, { replace: true, state: location.state });
  };

  const ErrorMessage = () => {
    if (!error) {
      return null;
    }

    let errorMessageToShow;
    let addDetailedMessage: JSX.Element | undefined;

    switch (error.type) {
      case ERROR_TYPES.INVALID_PHONE:
        return (
          <div styleName="error-message">
            {`We couldn't locate your account. To proceed, either reconfirm your phone number or start a new application at `}
            <a href={SCRATCHPAY_URL}>scratchpay.com</a>
            {`. If you think this is an error, please reach out to our Support Team for assistance.`}
          </div>
        );
      case ERROR_TYPES.EXCEED_ATTEMPTS:
        errorMessageToShow = `Uh-oh! Too many failed attempts. Need help? Call us at ${SUPPORT_CONTACT.PHONE_NUMBER_FORMATTED}.`;
        break;
      case ERROR_TYPES.INVALID_OTP:
        errorMessageToShow = `The confirmation code you've entered is invalid. Please try again.`;
        break;
      case ERROR_TYPES.OTP_EXPIRED:
        errorMessageToShow = `The confirmation code you've entered has expired. Please request new code and try again.`;
        break;
      case ERROR_TYPES.RECAPTCHA_EXPIRED:
        errorMessageToShow = 'reCAPTCHA expired. Please try again.';
        break;
      case ERROR_TYPES.VERIFICATION_ID_MISSING:
        errorMessageToShow = `SMS validation failure due to missing verification id. Please click 'Or Call me instead' below to request code via phone call.`;
        break;
      default:
        errorMessageToShow = `We are having technical difficulties. Please try again or reach out to our Support Team for assistance.`;
        if (error && error.message) {
          addDetailedMessage = (
            <>
              <br />
              {`-- ${error.message}`}
            </>
          );
        }
        break;
    }
    return (
      <div styleName="error-message">
        {errorMessageToShow}
        {addDetailedMessage}
      </div>
    );
  };

  const ResendButton = ({ type }: { type: string }) => (
    <div styleName="wrapper-btn-resend">
      <Tooltip
        title={
          resendButtonStates.isDisabled
            ? 'The confirmation code has already been resent'
            : `Request confirmation code be resent${type === AUTH_METHOD_VOICE_CALL ? ' via phone call' : ' via SMS'}`
        }
      >
        <span style={{ cursor: resendButtonStates.isDisabled ? 'not-allowed' : 'pointer' }}>
          <Button
            type="button"
            styleName="btn resend"
            disabled={resendButtonStates.isDisabled}
            onClick={() => handleOnResend(type)}
          >
            {type === AUTH_METHOD_SMS ? 'Resend' : 'Or Call me instead'}
          </Button>
        </span>
      </Tooltip>
    </div>
  );

  return (
    <div styleName="wrapper">
      <Header onBack={handleOnBackToLoginPage} styleOptions={{ isDarkBlue: true }} />
      <div styleName="wrapper-logo">
        <IconScratchpayHeart styleName="icon heart" />
        <img src={LogoScratchpayTextOnlyWhite} alt="logo-scratchpay" styleName="logo" />
      </div>
      <Col xs={12} sm={6} md={6}>
        {isLoadingRecaptcha || isSendingOTP ? (
          <LoadingSpinner />
        ) : (
          <KeyboardWrapper>
            <form aria-label="otpForm" onSubmit={handleOnFormSubmit}>
              <TextInput
                isWhiteStyle
                error={!!error}
                label="Confirmation code"
                type="tel"
                autoComplete="one-time-code"
                inputProps={{
                  maxLength: maxOTPLength,
                  name: 'one-time-code',
                  autoComplete: 'one-time-code',
                }}
                value={otp}
                onChange={handleOnChangeOTP}
              />
            </form>
            <ErrorMessage />
            <p styleName="disclaimer">{`Enter the confirmation code we have sent to ${phoneNumber.forDisplay}`}</p>
            {resendButtonStates.isDisplayed && (
              <>
                <ResendButton type={AUTH_METHOD_SMS} />
                <ResendButton type={AUTH_METHOD_VOICE_CALL} />
              </>
            )}
            <Fab
              disabled={isConfirmOTPButtonDisabled}
              onClick={() => {
                handleOnConfirmOTP();
              }}
            >
              {isConfirming ? 'Confirming...' : 'Confirm & log in'}
            </Fab>
          </KeyboardWrapper>
        )}
        <div
          id={RECAPTCHA_CONTAINER_ID}
          className="g-recaptcha"
          data-sitekey="_your_site_key_"
          data-callback="onSubmit"
          data-size="invisible"
        />
      </Col>
    </div>
  );
};

export default OTPConfirmation;
