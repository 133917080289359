import { DATE_FORMATS, formatAmount, formatDate, getAmountInDollars } from './format';

export const buildAutopayAuthValue = (loan: ILoan | null): IAutopayAuth => {
  if (!loan) {
    return {} as IAutopayAuth;
  }

  let paymentStartDate, paymentAmount, remainingInstallments;
  let index = -1;

  for (let i = 0; i < loan.paymentDues.length; i++) {
    if (loan.paymentDues[i].status === 'DUE') {
      index = i;
      break;
    }
  }

  if (index !== -1) {
    const unformattedStartDate = loan.dueDetails ? loan.dueDetails.nextDueDate : loan.paymentDues[index].dueAt;
    paymentStartDate = formatDate(unformattedStartDate, { outputFormat: DATE_FORMATS.MM_DD_YY });

    paymentAmount = formatAmount(
      getAmountInDollars(loan.paymentDues[index].amount.value, loan.paymentDues[index].amount.unit),
    );
    remainingInstallments = loan.dueDetails ? loan.dueDetails.dueInstallments : loan.paymentDues.length - index;
  } else {
    paymentStartDate = remainingInstallments = 'N/A';
  }

  return {
    loanId: loan.id,
    paymentStartDate,
    paymentAmount,
    remainingInstallments,
    frequency: loan.frequency,
    delinquentAmount: loan.dueDetails && loan.dueDetails.delinquentAmount,
  } as IAutopayAuth;
};

export const buildPaymentMethodValue = (
  paymentMethod: IPaymentMethod | null,
  paymentMethods: IPaymentMethod[],
): IPaymentMethod => {
  if (paymentMethod) {
    return paymentMethod;
  }

  if (!paymentMethods || paymentMethods.length === 0) {
    return {} as IPaymentMethod;
  }
  // return the isPrimary. If no primary payment method, return the most recent one
  // if more than one isPrimary, return the most recent one as well
  return paymentMethods.reduce((acc, curr) => {
    if (curr.isPrimary || curr.createdAt > acc.createdAt) {
      return curr;
    }
    return acc;
  });
};

export const buildPaymentMethodView = (paymentMethod: IPaymentMethod): string => {
  return `*${paymentMethod.last4}`;
};
