import { createAction, Action, BaseAction } from 'redux-actions';

export const actionTypes = {
  GET_PAYMENT_METHODS: 'GET_PAYMENT_METHODS',
  GET_PAYMENT_METHODS_REQUEST: 'GET_PAYMENT_METHODS_REQUEST',
  GET_PAYMENT_METHODS_SUCCESS: 'GET_PAYMENT_METHODS_SUCCESS',
  GET_PAYMENT_METHODS_ERROR: 'GET_PAYMENT_METHODS_ERROR',

  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  ADD_PAYMENT_METHOD_SUCCESS: 'ADD_PAYMENT_METHOD_SUCCESS',
  ADD_PAYMENT_METHOD_ERROR: 'ADD_PAYMENT_METHOD_ERROR',

  DELETE_PAYMENT_METHOD: 'DELETE_PAYMENT_METHOD',
  DELETE_PAYMENT_METHOD_SUCCESS: 'DELETE_PAYMENT_METHOD_SUCCESS',
  DELETE_PAYMENT_METHOD_ERROR: 'DELETE_PAYMENT_METHOD_ERROR',

  SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',

  GET_PLAID_LINK_TOKEN: 'GET_PLAID_LINK_TOKEN',
  GET_PLAID_LINK_TOKEN_SUCCESS: 'GET_PLAID_LINK_TOKEN_SUCCESS',
  GET_PLAID_LINK_TOKEN_ERROR: 'GET_PLAID_LINK_TOKEN_ERROR',
};

export interface IAddPaymentMethodPayload {
  data: IAddPaymentMethodRegularData | IAddPaymentMethodStripeACHData;
  onSuccess: (tokenId: string) => void;
  onError: () => void;
}

export interface IAddPaymentMethodRegularData {
  token: string;
  paymentMethod?: string;
  name?: string;
  last4: string;
  vendor: string;
  type?: string;
  expiry?: string;
  billingZip?: string;
  bankName?: string;
}

export interface IAddPaymentMethodStripeACHData {
  plaidPublicToken: string;
  plaidAccountId: string;
  paymentMethod?: string;
}

export interface IDeletePaymentMethodPayload {
  data: IDeletePaymentMethodData;
  onSuccess: () => void;
  onError: () => void;
}

export interface IDeletePaymentMethodData {
  tokenId: string;
}

export const getPaymentMethods: () => BaseAction = createAction(actionTypes.GET_PAYMENT_METHODS);

export const getPaymentMethodsRequest: () => BaseAction = createAction(actionTypes.GET_PAYMENT_METHODS_REQUEST);

export const getPaymentMethodsSuccess: (methods: IPaymentMethod[]) => Action<IPaymentMethod[]> = createAction(
  actionTypes.GET_PAYMENT_METHODS_SUCCESS
);

export const getPaymentMethodsError: () => Action<void> = createAction(actionTypes.GET_PAYMENT_METHODS_ERROR);

export const addPaymentMethod: (payload: IAddPaymentMethodPayload) => Action<IAddPaymentMethodPayload> = createAction(
  actionTypes.ADD_PAYMENT_METHOD
);

export const addPaymentMethodSuccess: () => BaseAction = createAction(actionTypes.ADD_PAYMENT_METHOD_SUCCESS);

export const addPaymentMethodError: () => BaseAction = createAction(actionTypes.ADD_PAYMENT_METHOD_ERROR);

export const deletePaymentMethod: (
  payload: IDeletePaymentMethodPayload
) => Action<IDeletePaymentMethodPayload> = createAction(actionTypes.DELETE_PAYMENT_METHOD);

export const deletePaymentMethodSuccess: (tokenId: string) => Action<string> = createAction(
  actionTypes.DELETE_PAYMENT_METHOD_SUCCESS
);

export const deletePaymentMethodError: () => BaseAction = createAction(actionTypes.DELETE_PAYMENT_METHOD_ERROR);

export const selectPaymentMethod: (paymentMethodId: string) => Action<string> = createAction(
  actionTypes.SELECT_PAYMENT_METHOD
);

export const getPlaidLinkToken: () => BaseAction = createAction(actionTypes.GET_PLAID_LINK_TOKEN);

export const getPlaidLinkTokenSuccess: (plaidLinkToken: string) => Action<string> = createAction(
  actionTypes.GET_PLAID_LINK_TOKEN_SUCCESS
);

export const getPlaidLinkTokenError = createAction(actionTypes.GET_PLAID_LINK_TOKEN_ERROR);
