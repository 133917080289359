import { combineReducers, UnknownAction } from '@reduxjs/toolkit';

import { actionTypes } from 'actions/user';
import app from './appSlice';
import user from './user';
import loan from './loan';
import paymentMethod from './paymentMethod';
import payment from './payment';
import internalServerErrorDialog from './internalServerErrorDialogSlice';
import convenienceFee from './convenienceFee';
import primaryPaymentMethodInfo from './primaryPaymentMethodInfoSlice';
import documents from './documents';

export const combinedReducers = combineReducers({
  app,
  user,
  loan,
  paymentMethod,
  payment,
  internalServerErrorDialog,
  convenienceFee,
  primaryPaymentMethodInfo,
  documents,
});

const rootReducer = (state: IRootState, action: UnknownAction) => {
  if (action.type === actionTypes.LOG_OUT) {
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};

export default rootReducer;
