import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import './IconLink.scss';
import { INAPPBROWSER_SETTINGS, isCordovaIOSApp } from 'utils/constants';

interface IProps {
  path: string;
  icon: JSX.Element;
  text?: string;
  isExternal?: boolean;
}

const IconLink = (props: IProps) => {
  const { icon, text, path, isExternal = false } = props;
  const LinkNode = () => <div styleName="icon">{icon}</div>;
  const TextNode = () => (text ? <div styleName="text">{text}</div> : null);
  const BoxNode = () => (
    <Box styleName="link">
      <LinkNode />
      <TextNode />
    </Box>
  );

  if (isCordovaIOSApp) {
    return (
      <Box
        onClick={() =>
          window.cordova.InAppBrowser.open(
            path,
            INAPPBROWSER_SETTINGS.OPEN_IN_SYSTEM_BROWSER,
            INAPPBROWSER_SETTINGS.SHOW_LOCATION_BAR,
          )
        }
        styleName="link"
      >
        <LinkNode />
        <TextNode />
      </Box>
    );
  }

  if (isExternal) {
    return (
      <a href={path} target="_blank" rel="noreferrer">
        <BoxNode />
      </a>
    );
  }

  return (
    <Link to={path}>
      <BoxNode />
    </Link>
  );
};

export default IconLink;
