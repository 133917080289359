import { createSelector } from '@reduxjs/toolkit';
import { PAYMENT_METHOD_VENDORS } from 'utils/constants';
import { getUseablePaymentMethodsByLoan } from 'utils/loan';

const paymentMethodStateSelector = (state: IRootState) => state.paymentMethod;

// TODO: this selector is not used, remove
export const paymentMethodByIdSelector = createSelector(
  paymentMethodStateSelector,
  ({ paymentMethodById }) => paymentMethodById,
);

export const useablePaymentMethodsByLoanSelector = (loan: any) =>
  createSelector(
    payixPaymentMethodsSelector,
    stripePaymentMethodsSelector,
    (payixPaymentMethods, stripePaymentMethods) => {
      return getUseablePaymentMethodsByLoan({
        loan,
        payixPaymentMethods,
        stripePaymentMethods,
      });
    },
  );

export const paymentMethodsSelector = createSelector(
  paymentMethodStateSelector,
  ({ paymentMethodById, paymentMethodIds }) => paymentMethodIds.map((id) => paymentMethodById[id]),
);
export const stripePaymentMethodsSelector = createSelector(paymentMethodsSelector, (paymentMethods) =>
  paymentMethods.filter(({ vendor }) => vendor === PAYMENT_METHOD_VENDORS.STRIPE),
);
export const payixPaymentMethodsSelector = createSelector(paymentMethodsSelector, (paymentMethods) =>
  paymentMethods.filter(({ vendor }) => vendor === PAYMENT_METHOD_VENDORS.PAYIX),
);

export const paymentMethodCountSelector = createSelector(
  paymentMethodsSelector,
  (paymentMethods) => paymentMethods.length,
);
// TODO: this selector is not used, remove
export const stripePaymentMethodCountSelector = createSelector(
  stripePaymentMethodsSelector,
  (stripePaymentMethods) => stripePaymentMethods.length,
);
// TODO: this selector is not used, remove
export const payixPaymentMethodCountSelector = createSelector(
  payixPaymentMethodsSelector,
  (payixPaymentMethods) => payixPaymentMethods.length,
);

export const isFetchingSelector = createSelector(paymentMethodStateSelector, ({ isFetching }) => isFetching);

export const isFetchedSelector = createSelector(paymentMethodStateSelector, ({ isFetched }) => isFetched);

export const isAddingSelector = createSelector(paymentMethodStateSelector, ({ isAdding }) => isAdding);

// TODO: this selector is not used, remove
export const isDeletingSelector = createSelector(paymentMethodStateSelector, ({ isDeleting }) => isDeleting);

export const selectedPaymentMethodSelector = createSelector(
  paymentMethodStateSelector,
  ({ paymentMethodById, selectedPaymentMethodId }) =>
    selectedPaymentMethodId ? paymentMethodById[selectedPaymentMethodId] : null,
);

export const plaidLinkTokenSelector = createSelector(
  paymentMethodStateSelector,
  ({ plaidLinkToken }) => plaidLinkToken,
);

// TODO: this selector is not used, remove
export const isFetchedPlaidLinkTokenSelector = createSelector(
  paymentMethodStateSelector,
  ({ isFetchedPlaidLinkToken }) => isFetchedPlaidLinkToken,
);

export const isFetchingPlaidLinkTokenSelector = createSelector(
  paymentMethodStateSelector,
  ({ isFetchingPlaidLinkToken }) => isFetchingPlaidLinkToken,
);
