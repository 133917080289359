import { createSelector } from '@reduxjs/toolkit';

export const documentsStateSelector = (state: IRootState) => state.documents;

export const isFetchingDocumentsSelector = createSelector(
  documentsStateSelector,
  ({ isFetchingDocuments }) => isFetchingDocuments,
);

export const isFetchedDocumentsSelector = createSelector(
  documentsStateSelector,
  ({ isFetchedDocuments }) => isFetchedDocuments,
);

export const documentsSelector = createSelector(documentsStateSelector, ({ documents }) => documents);

export const totalDocumentsSelector = createSelector(documentsStateSelector, ({ totalDocuments }) => totalDocuments);
