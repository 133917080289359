import { useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import { setSelectedLoanId, toggleAutopay } from 'actions/loan';
import { showNotification } from 'utils/notification';
import * as loanSelectors from 'selectors/loan';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import * as primaryPaymentMethodInfoSelector from 'selectors/primaryPaymentMethodInfo';
import { routes } from 'utils/routeHelper';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux-hooks';

const useAutopayToggle = (loan: ILoan) => {
  const [isOpenDisableAutopayDialog, setIsOpenDisableAutopayDialog] = useState(false);
  const [isOpenEnableAutopayDialog, setIsOpenEnableAutopayDialog] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loanId = loan.id;
  const useablePaymentMethodsByLoan = useSelector(paymentMethodSelectors.useablePaymentMethodsByLoanSelector(loan));
  const isAutopayEnabled = useSelector(loanSelectors.isAutopayEnabledSelector(loanId));
  const isTogglingAutopay = useSelector(loanSelectors.isTogglingAutopaySelector(loanId));
  const isSettingPrimaryPaymentMethod = useSelector(loanSelectors.isSettingPrimaryPaymentMethodSelector(loanId));
  const isTake5Loan = useSelector(loanSelectors.isTake5LoanSelector(loanId));
  const isPendingLoan = useSelector(loanSelectors.isPendingLoanSelector(loanId));
  const isFetchingPaymentMethods = useSelector(paymentMethodSelectors.isFetchingSelector);
  const isAddPrimaryPaymentMethod = useSelector(primaryPaymentMethodInfoSelector.isAddPrimaryPaymentMethodSelector);
  const primaryPaymentMethodInfoLoanId = useSelector(
    primaryPaymentMethodInfoSelector.primaryPaymentMethodInfoLoanIdSelector,
  );
  const isProcessingWithAutopay = isTogglingAutopay || isSettingPrimaryPaymentMethod;
  useEffect(() => {
    if (isAddPrimaryPaymentMethod && primaryPaymentMethodInfoLoanId === loanId) {
      setIsOpenEnableAutopayDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnCloseEnableAutopayDialog = () => setIsOpenEnableAutopayDialog(false);
  const handleOnCloseDisableAutopayDialog = () => setIsOpenDisableAutopayDialog(false);

  const handleOnToggleAutopay = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (loan.restructured === 'src') return;
    const expectToEnableAutopay = !isAutopayEnabled && checked;
    const expectToDisableAutopay = isAutopayEnabled && !checked;

    if (isPendingLoan) {
      showNotification(`You can't ${expectToEnableAutopay ? 'enable' : 'disable'} Autopay on a pending payment plan`, {
        variant: 'info',
      });
      return;
    }

    if (isTake5Loan) {
      showNotification(`You can't ${expectToEnableAutopay ? 'enable' : 'disable'} Autopay on a Take5 payment plan`, {
        variant: 'info',
      });
      return;
    }

    if (expectToDisableAutopay) {
      setIsOpenDisableAutopayDialog(true);
      return;
    }

    dispatch(setSelectedLoanId(loan.id));
    navigate(routes.AUTOPAY_ACTIVATION);
  };

  const handleOnDisableAutopay = () => {
    dispatch(
      toggleAutopay({
        data: {
          loanId,
          isEnabled: false,
        },
      }),
    );
    setIsOpenDisableAutopayDialog(false);
  };

  return {
    isAutopayEnabled,
    isProcessingWithAutopay,
    isOpenEnableAutopayDialog,
    setIsOpenEnableAutopayDialog,
    handleOnCloseEnableAutopayDialog,
    isOpenDisableAutopayDialog,
    setIsOpenDisableAutopayDialog,
    handleOnToggleAutopay,
    // Used with disable autopay dialog
    handleOnCloseDisableAutopayDialog,
    handleOnDisableAutopay,
    // Used with enable autopay dialog
    isFetchingPaymentMethods,
    useablePaymentMethodsByLoan,
  };
};

export default useAutopayToggle;
