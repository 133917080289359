import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: IApiErrorDialogState = {
  isVisible: false,
};

const internalServerErrorDialogSlice = createSlice({
  name: 'internalServerErrorDialog',
  initialState,
  reducers: {
    setInternalServerErrorDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
  },
});

export const { setInternalServerErrorDialogVisible } = internalServerErrorDialogSlice.actions;

export default internalServerErrorDialogSlice.reducer;
