import { createAction, Action, BaseAction } from 'redux-actions';

export const actionTypes = {
  GET_CACHING_LOANS_REQUEST: 'GET_CACHING_LOANS_REQUEST',
  GET_CACHING_LOANS_SUCCESS: 'GET_CACHING_LOANS_SUCCESS',

  RENEW_LOANS: 'RENEW_LOANS',
  SYNCHRONIZE_LOANS_REQUEST: 'SYNCHRONIZE_LOANS_REQUEST',
  SYNCHRONIZE_LOANS_SUCCESS: 'SYNCHRONIZE_LOANS_SUCCESS',
  SYNCHRONIZE_LOANS_ERROR: 'SYNCHRONIZE_LOANS_ERROR',

  TOGGLE_AUTOPAY: 'TOGGLE_AUTOPAY',
  TOGGLE_AUTOPAY_REQUEST: 'TOGGLE_AUTOPAY_REQUEST',
  TOGGLE_AUTOPAY_SUCCESS: 'TOGGLE_AUTOPAY_SUCCESS',
  TOGGLE_AUTOPAY_ERROR: 'TOGGLE_AUTOPAY_ERROR',

  SET_PRIMARY_PAYMENT_METHOD: 'SET_PRIMARY_PAYMENT_METHOD',
  SET_PRIMARY_PAYMENT_METHOD_REQUEST: 'SET_PRIMARY_PAYMENT_METHOD_REQUEST',
  SET_PRIMARY_PAYMENT_METHOD_SUCCESS: 'SET_PRIMARY_PAYMENT_METHOD_SUCCESS',
  SET_PRIMARY_PAYMENT_METHOD_ERROR: 'SET_PRIMARY_PAYMENT_METHOD_ERROR',

  SET_SELECTED_LOAN_ID: 'SET_SELECTED_LOAN_ID',
};

export interface IToggleAutopayData {
  loanId: string;
  tokenId?: string;
  isEnabled: boolean;
}

export interface ISetPrimaryPaymentMethodData {
  loanId: string;
  tokenId: string;
}

interface IToggleAutopayPayload {
  data: IToggleAutopayData;
  onSuccess?: () => void;
  onError?: () => void;
}

interface ISetPrimaryPaymentMethodPayload {
  data: ISetPrimaryPaymentMethodData;
}

export const getCachingLoansRequest: () => BaseAction = createAction(actionTypes.GET_CACHING_LOANS_REQUEST);
export const getCachingLoansSuccess: (loans: ILoan[]) => Action<ILoan[]> = createAction(
  actionTypes.GET_CACHING_LOANS_SUCCESS
);

export const renewLoans: () => BaseAction = createAction(actionTypes.RENEW_LOANS);
export const synchronizeLoansRequest: () => BaseAction = createAction(actionTypes.SYNCHRONIZE_LOANS_REQUEST);
export const synchronizeLoansSuccess: () => BaseAction = createAction(actionTypes.SYNCHRONIZE_LOANS_SUCCESS);
export const synchronizeLoansError: () => BaseAction = createAction(actionTypes.SYNCHRONIZE_LOANS_ERROR);

export const toggleAutopay: (payload: IToggleAutopayPayload) => Action<IToggleAutopayPayload> = createAction(
  actionTypes.TOGGLE_AUTOPAY
);
export const toggleAutopaySuccess: (loanId: string) => Action<string> = createAction(
  actionTypes.TOGGLE_AUTOPAY_SUCCESS
);
export const toggleAutopayError: (loanId: string) => Action<string> = createAction(actionTypes.TOGGLE_AUTOPAY_ERROR);

export const setPrimaryPaymentMethod: (
  payload: ISetPrimaryPaymentMethodPayload
) => Action<ISetPrimaryPaymentMethodPayload> = createAction(actionTypes.SET_PRIMARY_PAYMENT_METHOD);
export const setPrimaryPaymentMethodSuccess: (loanId: string) => Action<string> = createAction(
  actionTypes.SET_PRIMARY_PAYMENT_METHOD_SUCCESS
);
export const setPrimaryPaymentMethodError: (loanId: string) => Action<string> = createAction(
  actionTypes.SET_PRIMARY_PAYMENT_METHOD_ERROR
);

export const setSelectedLoanId: (loanId: string) => Action<string> = createAction(actionTypes.SET_SELECTED_LOAN_ID);
