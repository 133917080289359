import { Navigate } from 'react-router-dom';

import { routes } from 'utils/routeHelper';
import { ScratchAuth } from 'hooks/useAuthenticationSubscriber';

type AuthRouteProps = {
  children: JSX.Element;
  currentUser: ScratchAuth;
};

const AuthRoute = ({ children, currentUser }: AuthRouteProps) => {
  const isAuthenticated = currentUser && currentUser.scratchBorrowerId;

  if (isAuthenticated) {
    return <Navigate to={routes.HOME} replace />;
  }
  return children;
};

export default AuthRoute;
