import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { capitalize } from 'lodash';

import { borrowerSelector } from 'selectors/user';
import { ElevationAppbar, NavigationDrawer } from 'components/widgets';

import './HomeHeader.scss';

const HomeHeader = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const borrower = useSelector(borrowerSelector);

  const fullName = borrower
    ? [borrower.firstName, borrower.lastName]
        .filter((name) => name)
        .map(capitalize)
        .join(' ')
    : '';

  const onToggleDrawer = (isOpen: boolean) => setIsOpenDrawer(isOpen);

  return (
    <Fragment>
      <ElevationAppbar styleName="wrapper main-header">
        <IconButton onClick={() => onToggleDrawer(true)} styleName="btn btn-toggle-drawer" size="large">
          <MenuIcon styleName="icon menu" />
        </IconButton>
      </ElevationAppbar>
      <NavigationDrawer
        fullName={fullName}
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={() => onToggleDrawer(false)}
        onOpenDrawer={() => onToggleDrawer(true)}
      />
    </Fragment>
  );
};

export default HomeHeader;
