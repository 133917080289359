import { IconScratchpayHeart } from 'assets/icons';
import { Footer, Header } from 'components/layouts';
import { Col, EFTAgreementBody, IconWrapper } from 'components/widgets';
import './EFTAgreement.scss';
import { useLocation } from 'react-router-dom';
import { getBorrowerFullName } from 'utils/format';

export interface EFTState {
  borrower: {
    firstName: string;
    lastName: string;
  };
  paymentDetails: {
    amount: number;
    paymentMethodType: PaymentMethodType;
    paymentMethodLast4: string;
  };
}

const EFTAgreement = () => {
  const location = useLocation();
  const borrower = location.state.borrower || {};
  const paymentDetails = location.state.paymentDetails || {};

  return (
    <div styleName="wrapper">
      <Header styleOptions={{ isSticky: false, isLight: true }} />
      <Col xs={12} sm={6} md={4} styleName="col">
        <IconWrapper>
          <IconScratchpayHeart />
        </IconWrapper>
        <EFTAgreementBody
          borrowerName={getBorrowerFullName(borrower)}
          paymentAmount={paymentDetails.amount || 0}
          paymentDate={new Date().toISOString()}
          paymentMethodType={paymentDetails.paymentMethodType}
          paymentMethodLast4={paymentDetails.paymentMethodLast4}
        />
      </Col>
      <Footer />
    </div>
  );
};

export default EFTAgreement;
