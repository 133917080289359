import { createAction, Action, BaseAction } from 'redux-actions';

export const actionTypes = {
  RENEW_BORROWER: 'RENEW_BORROWER',
  RENEW_BORROWER_REQUEST: 'RENEW_BORROWER_REQUEST',
  RENEW_BORROWER_SUCCESS: 'RENEW_BORROWER_SUCCESS',
  RENEW_BORROWER_ERROR: 'RENEW_BORROWER_ERROR',

  GET_CACHED_BORROWER_REQUEST: 'GET_CACHED_BORROWER_REQUEST',
  GET_CACHED_BORROWER_SUCCESS: 'GET_CACHED_BORROWER_SUCCESS',

  CREATE_APPLICATION_URL: 'CREATE_APPLICATION_URL',
  CREATE_APPLICATION_URL_SUCCESS: 'CREATE_APPLICATION_URL_SUCCESS',
  CREATE_APPLICATION_URL_ERROR: 'CREATE_APPLICATION_URL_ERROR',

  LOG_OUT: 'LOG_OUT',
  SET_SIGN_IN_SUCCESS_URL: 'SET_SIGN_IN_SUCCESS_URL',
};

interface ILogoutPayload {
  onError: Function;
}

export const logout: (payload?: ILogoutPayload) => Action<ILogoutPayload> = createAction(actionTypes.LOG_OUT);
export const setSignInSuccessUrl: (payload: string) => Action<string> = createAction(
  actionTypes.SET_SIGN_IN_SUCCESS_URL
);

export const renewBorrower: () => BaseAction = createAction(actionTypes.RENEW_BORROWER);
export const renewBorrowerRequest: () => BaseAction = createAction(actionTypes.RENEW_BORROWER_REQUEST);
export const renewBorrowerSuccess: () => BaseAction = createAction(actionTypes.RENEW_BORROWER_SUCCESS);
export const renewBorrowerError: () => BaseAction = createAction(actionTypes.RENEW_BORROWER_ERROR);

export const getCachedBorrowerRequest: () => BaseAction = createAction(actionTypes.GET_CACHED_BORROWER_REQUEST);
export const getCachedBorrowerSuccess: (borrower: Nullable<IBorrower>) => Action<Nullable<IBorrower>> = createAction(
  actionTypes.GET_CACHED_BORROWER_SUCCESS
);

export const createApplicationUrl: () => BaseAction = createAction(actionTypes.CREATE_APPLICATION_URL);
export const createApplicationUrlSuccess: () => BaseAction = createAction(actionTypes.CREATE_APPLICATION_URL_SUCCESS);
export const createApplicationUrlError: () => BaseAction = createAction(actionTypes.CREATE_APPLICATION_URL_ERROR);
