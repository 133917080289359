import { Dialog } from 'components/widgets';
import { Props as CustomizedDialogProps } from 'components/widgets/Dialog';

import './LateFeeDialog.scss';

type Props = Pick<CustomizedDialogProps, 'isOpen' | 'onClose'> & {
  onPayNow: () => void;
};

const LateFeeDialog = (props: Props) => (
  <Dialog
    isOpen={props.isOpen}
    onClose={props.onClose}
    title="All late fees must be paid"
    actions={[
      {
        text: 'Cancel',
        type: 'negative',
        onClick: props.onClose,
      },
      {
        text: 'Pay now',
        onClick: props.onPayNow,
      },
    ]}
  >
    <p styleName="late-fee-dialog-info">You have missed more than one scheduled payment.</p>
    <p styleName="late-fee-dialog-info">All outstanding late fees will be charged with your next payment.</p>
    <p styleName="late-fee-dialog-info">
      To bring back your account to current you will still have to pay the remaining due
    </p>
  </Dialog>
);

export default LateFeeDialog;
