import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects';

import * as apis from 'apis';
import {
  actionTypes,
  IAddPaymentMethodRegularData,
  IAddPaymentMethodStripeACHData,
  IDeletePaymentMethodData,
  getPaymentMethodsRequest,
  getPaymentMethodsSuccess,
  getPaymentMethodsError,
  addPaymentMethodSuccess,
  addPaymentMethodError,
  deletePaymentMethodSuccess,
  deletePaymentMethodError,
  getPlaidLinkTokenSuccess,
  getPlaidLinkTokenError,
} from 'actions/paymentMethod';
import { isFetchingSelector } from 'selectors/paymentMethod';
import { BaseAction } from 'redux-actions';

export function* getPlaidLinkToken() {
  try {
    const { result: plaidLinkToken } = yield call(apis.getPlaidLinkToken);
    yield put(getPlaidLinkTokenSuccess(plaidLinkToken));
  } catch (error) {
    yield put(getPlaidLinkTokenError());
  }
}

export function* getPaymentMethods(): Generator<BaseAction, void, any> {
  const isFetching = yield select(isFetchingSelector);
  if (!isFetching) {
    try {
      yield put(getPaymentMethodsRequest());
      const { result: methods } = yield call(apis.getPaymentMethods);
      yield put(getPaymentMethodsSuccess(methods));
    } catch (error) {
      yield put(getPaymentMethodsError());
    }
  }
}

export function* addPaymentMethod(action: IActionSaga<IAddPaymentMethodRegularData | IAddPaymentMethodStripeACHData>) {
  const { data, onSuccess, onError } = action.payload;
  try {
    const { result: tokenId } = yield call(apis.addPaymentMethod, data);
    yield put(addPaymentMethodSuccess());
    if (onSuccess) onSuccess(tokenId);
  } catch (error) {
    yield put(addPaymentMethodError());
    if (onError) onError();
  }
}

export function* deletePaymentMethod(action: IActionSaga<IDeletePaymentMethodData>) {
  const { data, onSuccess, onError } = action.payload;
  try {
    yield call(apis.deletePaymentMethod, data.tokenId);
    yield put(deletePaymentMethodSuccess(data.tokenId));
    if (onSuccess) onSuccess();
  } catch (error) {
    yield put(deletePaymentMethodError());
    if (onError) onError();
  }
}

export default function* paymentMethodWatcher() {
  yield takeLatest(actionTypes.GET_PLAID_LINK_TOKEN, getPlaidLinkToken);
  yield takeEvery(actionTypes.GET_PAYMENT_METHODS, getPaymentMethods);
  yield takeLatest(actionTypes.ADD_PAYMENT_METHOD, addPaymentMethod);
  yield takeLatest(actionTypes.DELETE_PAYMENT_METHOD, deletePaymentMethod);
}
