import {
  getConvenienceFeeError,
  getConvenienceFeeRequest,
  getConvenienceFeeSuccess,
  actionTypes,
} from 'actions/convenienceFee';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { isFetchingConvenienceFeeSelector } from 'selectors/convenienceFee';
import * as apis from 'apis';
import { BaseAction } from 'redux-actions';

export function* getConvenienceFee(action: IActionSaga<IConvenienceFeeFactors>): Generator<BaseAction, void, any> {
  const { data } = action.payload;
  const isFetching = yield select(isFetchingConvenienceFeeSelector);
  if (!isFetching) {
    try {
      yield put(getConvenienceFeeRequest());
      const { result: convenienceFee } = yield call(apis.getConvenienceFee, data);
      yield put(getConvenienceFeeSuccess(convenienceFee));
    } catch (error) {
      yield put(getConvenienceFeeError());
    }
  }
}

export default function* convenienceFeeWatcher() {
  yield takeLatest(actionTypes.GET_CONVENIENCE_FEE, getConvenienceFee);
}
