import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';

import * as apis from 'apis';
import {
  actionTypes,
  createApplicationUrlSuccess,
  createApplicationUrlError,
  renewBorrowerRequest,
  renewBorrowerSuccess,
  renewBorrowerError,
} from 'actions/user';
import { isRenewingBorrowerSelector } from 'selectors/user';
import { redirectToExternalUrl } from 'utils/routeHelper';
import { showNotification } from 'utils/notification';
import firebaseClient from 'utils/firebase';
import { getAuth, signOut } from 'firebase/auth';

export function* logout({ payload }: IActionSaga) {
  try {
    const auth = getAuth(firebaseClient);
    yield signOut(auth);
  } catch (error) {
    if (payload && payload.onError) payload.onError();
  }
}

export function* renewBorrower(): Generator {
  const isRenewingBorrower = yield select(isRenewingBorrowerSelector);
  if (!isRenewingBorrower) {
    try {
      yield put(renewBorrowerRequest());
      yield call(apis.renewBorrower);
      yield put(renewBorrowerSuccess());
    } catch (error) {
      yield put(renewBorrowerError());
    }
  }
}

export function* createApplicationUrl() {
  try {
    const { result: applicationUrl } = yield call(apis.createApplicationUrl);
    yield put(createApplicationUrlSuccess());
    yield redirectToExternalUrl(applicationUrl);
  } catch (error) {
    yield put(createApplicationUrlError());
    yield showNotification('Cannot apply for a new payment plan at the moment. Please retry later.', {
      variant: 'error',
    });
  }
}

export default function* userWatcher() {
  yield takeLatest(actionTypes.LOG_OUT, logout);
  yield takeEvery(actionTypes.RENEW_BORROWER, renewBorrower);
  yield takeLatest(actionTypes.CREATE_APPLICATION_URL, createApplicationUrl);
}
