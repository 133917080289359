import { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { OutlinedTextFieldProps } from '@mui/material/TextField';

import { routes } from 'utils/routeHelper';

import { LoadingSpinner, SelectField, PaymentMethodListItem } from 'components/widgets';

import { IconAddCircle } from 'assets/icons';

import './PaymentMethodDropdown.scss';

type Props = Partial<OutlinedTextFieldProps> & {
  label?: Nullable<string>;
  paymentMethods: IPaymentMethod[];
  isFetching: boolean;
  onBeforeOpen?: () => void;
  onBeforeGoToAddPaymentMethod?: () => void;
  showAddPaymentMethod?: boolean;
};

const PaymentMethodDropdown = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    label,
    disabled,
    paymentMethods,
    isFetching,
    onBeforeOpen,
    onBeforeGoToAddPaymentMethod,
    showAddPaymentMethod = true,
    ...restProps
  } = props;

  const mergedProps = {
    ...restProps,
    disabled,
    label: label === null ? null : 'Payment Method',
    SelectProps: {
      onOpen: () => {
        onBeforeOpen && onBeforeOpen();
        setIsOpen(true);
      },
      onClose: () => setIsOpen(false),
      renderValue: (selectedValue: any) => {
        const selectedMethod = paymentMethods.find((method) => method.id === selectedValue);
        return selectedMethod ? (
          <div styleName={classNames('selected-payment-method', { disabled })}>
            {isFetching && !isOpen && <LoadingSpinner size={14} styleName="spinner" />}
            <PaymentMethodListItem method={selectedMethod} displayHiddenDigits={false} displayHoldername={false} />
          </div>
        ) : null;
      },
    },
    extraProps: { isOpen },
  };

  const handleOnGoToAddPaymentMethod = () => {
    onBeforeGoToAddPaymentMethod && onBeforeGoToAddPaymentMethod();
  };

  return (
    <SelectField {...mergedProps}>
      {paymentMethods.map((method: IPaymentMethod) => {
        const customStyle = classNames('menu-item default-item payment-method-item', {
          selected: method.id === props.value,
        });
        return (
          <MenuItem key={method.id} value={method.id} styleName={customStyle}>
            <PaymentMethodListItem method={method} />
          </MenuItem>
        );
      })}
      {showAddPaymentMethod && (
        <div>
          {isFetching && <LoadingSpinner size={22} styleName="custom loading" />}
          <Link
            to={routes.PAYMENT_METHOD_DETAILS}
            onClick={handleOnGoToAddPaymentMethod}
            className="extra-item-wrapper"
          >
            <IconAddCircle />
            <span>Add payment method</span>
          </Link>
        </div>
      )}
    </SelectField>
  );
};

export default PaymentMethodDropdown;
