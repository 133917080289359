import { ILoanOverviewValue } from 'utils/loan';

import { Col, LoadingSpinner } from 'components/widgets';

import './LoanOverviewTabDetails.scss';

interface IProps {
  isFetching: boolean;
  isPaidoff: boolean;
  details: ILoanOverviewValue;
}

export const LoanOverviewTabDetails = (props: IProps) => {
  const { isFetching, isPaidoff, details } = props;

  const renderOverviewTile = (title: string, subTitle: string, value: number | string, isSpinnerVisible = false) => {
    return (
      <div styleName="tile">
        <div styleName="title">{title}</div>
        {subTitle && <div styleName="sub-title">{`(${subTitle})`}</div>}
        <div styleName="value">
          {value}
          {!isPaidoff && isSpinnerVisible && <LoadingSpinner size={15} styleName="custom loading" />}
        </div>
      </div>
    );
  };

  return (
    <Col xs={12} sm={6} md={4}>
      <div styleName="wrapper">
        {renderOverviewTile('Total finalized', 'without interest', details.finalizedAmount)}
        {details.annualPercentageRate &&
          renderOverviewTile('Annual Percentage\nRate (APR)', '', details.annualPercentageRate)}
        {renderOverviewTile('Remaining', 'incl. interest & fees', details.payoffBalance, isFetching)}
        {renderOverviewTile('Remaining principal', 'exl. Interest', details.principalBalance, isFetching)}
        {renderOverviewTile('Regular payment', '', details.regularAmount, isFetching)}
        {renderOverviewTile('Frequency', '', details.frequency)}
        {renderOverviewTile('Number of payments', '', details.planTerm)}
      </div>
    </Col>
  );
};

export default LoanOverviewTabDetails;
