import {
  SentimentSatisfiedOutlined as SentimentSatisfiedOutlinedIcon,
  ContactSupportOutlined as ContactSupportOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';

import './Notification.scss';

export type NotificationVariant = 'info' | 'error' | 'success';
interface IProps {
  message: string;
  variant: NotificationVariant;
}

const Notification = ({ message, variant }: IProps) => {
  const styleName = `wrapper ${variant}`;

  const renderIcon = () => {
    switch (variant) {
      case 'info':
        return <InfoOutlinedIcon />;
      case 'success':
        return <SentimentSatisfiedOutlinedIcon />;
      case 'error':
        return <ContactSupportOutlinedIcon />;
      default:
        return null;
    }
  };

  return (
    <div styleName={styleName}>
      <div styleName="icon-wrapper">{renderIcon()}</div>
      <div>{message}</div>
    </div>
  );
};

export default Notification;
