import { useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'redux-hooks';
import { setSignInSuccessUrl } from 'actions/user';
import { routes } from 'utils/routeHelper';
import { ScratchAuth } from 'hooks/useAuthenticationSubscriber';

type RequireAuthProps = {
  children: JSX.Element;
  currentUser: ScratchAuth;
};

const RequireAuth = ({ children, currentUser }: RequireAuthProps) => {
  const dispatch = useAppDispatch();
  const currentUserRef = useRef(currentUser);
  const location = useLocation();

  const isAuthenticated = currentUser && currentUser.scratchBorrowerId;

  if (isAuthenticated) {
    return children;
  }
  const saveSignInSuccessUrlIfNeeded = () => {
    if (!currentUserRef.current) {
      dispatch(setSignInSuccessUrl(location.pathname));
    }
  };

  saveSignInSuccessUrlIfNeeded();
  return <Navigate to={routes.LOGIN} replace />;
};

export default RequireAuth;
