import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';

import * as apis from 'apis';
import {
  IMakePaymentData,
  ISendReceiptData,
  actionTypes,
  getPaymentsSuccess,
  getPaymentsError,
  getPaymentsRequest,
} from 'actions/payment';
import { isFetchingPaymentsSelector } from 'selectors/payment';
import { BaseAction } from 'redux-actions';
import { extractErrorMessage } from 'utils/validate';

export function* getPayments(): Generator<BaseAction, void, any> {
  const isFetching = yield select(isFetchingPaymentsSelector);
  if (!isFetching) {
    try {
      yield put(getPaymentsRequest());
      const { result: payments } = yield call(apis.getPayments);
      yield put(getPaymentsSuccess(payments));
    } catch (error) {
      yield put(getPaymentsError());
    }
  }
}

export function* makePayment(action: IActionSaga<IMakePaymentData>) {
  const { data, onSuccess, onError } = action.payload;
  try {
    yield call(apis.makePayment, data);
    if (onSuccess) onSuccess();
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    if (onError) onError(errorMessage);
  }
}

export function* sendReceipt(action: IActionSaga<ISendReceiptData>) {
  const { data, onSuccess, onError } = action.payload;

  try {
    yield call(apis.sendReceipt, data);
    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError();
  }
}

export default function* paymentWatcher() {
  yield takeEvery(actionTypes.GET_PAYMENTS, getPayments);
  yield takeLatest(actionTypes.MAKE_PAYMENT, makePayment);
  yield takeLatest(actionTypes.SEND_RECEIPT, sendReceipt);
}
