import { ComponentProps } from 'react';
import { Button } from '@mui/material';
import classNames from 'classnames';

import './ActionButton.scss';

type TButtonProps = ComponentProps<typeof Button>;
interface IProps {
  children: string;
  customVariant: 'standard' | 'info' | 'alert' | 'disabled';
}

const ActionButton = (props: IProps & TButtonProps) => {
  const { children, customVariant, disabled, size = 'large', ...rest } = props;
  const isDisabled = disabled || customVariant === 'disabled';

  return (
    <div styleName={classNames('wrapper', { [`btn-${customVariant}`]: true })}>
      <Button disabled={isDisabled} size={size} {...rest}>
        {children}
      </Button>
    </div>
  );
};

export default ActionButton;
