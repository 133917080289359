import { useMemo } from 'react';

type StorageType = 'local' | 'session';

const getStorageType = (type: StorageType): Storage => {
  if (type === 'local') return localStorage;
  return sessionStorage;
};

const getStorageItem = (storage: Storage, key: string): unknown => {
  const item = storage.getItem(key) || '';
  try {
    if (typeof item === 'string') {
      return item;
    } else {
      return JSON.parse(item);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err instanceof Error ? err.message : err);
    return item || null;
  }
};

const setStorageItem = (storage: Storage, key: string, item: unknown): void => {
  try {
    storage.setItem(key, JSON.stringify(item));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err instanceof Error ? err.message : err);
  }
};

/**
 * A hook for getting and/or setting
 * local or session storage
 * @param storageType
 * @param key
 * @param defaultValue
 * @returns
 */
const useClientStorage = (storageType: StorageType, key: string) => {
  const storage = useMemo(() => getStorageType(storageType), [storageType]);
  const getItem = () => getStorageItem(storage, key);

  const handleSetStorageItem = (newItem: unknown) => {
    setStorageItem(storage, key, newItem);
  };

  const handleRemoveStorageItem = () => {
    storage.removeItem(key);
  };

  return { getItem, handleSetStorageItem, handleRemoveStorageItem };
};

export default useClientStorage;
