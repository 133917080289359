import * as Sentry from '@sentry/react';

import { isCordovaApp, SENTRY_DSN, SENTRY_PROJECT_ID } from 'utils/constants';
import { EnvironmentHelper } from 'utils/environmentHelper';

let sentryClient: any;

export const initializeSentry = (params: { environment: string }) => {
  sentryClient = isCordovaApp ? window.cordova.require('sentry-cordova.Sentry') : Sentry;
  const { environment } = params;
  const dsn = parseDSN(SENTRY_DSN, SENTRY_PROJECT_ID, environment);

  if (!dsn) {
    return;
  }

  sentryClient.init({
    dsn: dsn,
    environment: environment,
  });
};

export const captureException = (error: Error) => {
  if (!sentryClient || !sentryClient.captureException) {
    return;
  }
  sentryClient.captureException(error);
};

export const configureScopeWithUserId = (userId: string) => {
  if (!sentryClient) {
    return;
  }
  sentryClient.getCurrentScope().setUser({ id: userId });
};

export const parseDSN = (dsn: string, projectId: string, environment: string) => {
  const dsnProjectId = typeof dsn === 'string' ? dsn.split('/').pop() : null;
  // this is a workaround to avoid sending sentry alerts from development
  // to the sentry project used in production/staging
  if (EnvironmentHelper.isDevelopment(environment) && projectId && dsnProjectId === projectId) {
    return '';
  }
  return dsn;
};
