import { ENABLE_MAVEN_WIDGET, MAVEN_AGI, MAVEN_SCRIPT_URL } from './constants.ts';
import { routes } from './routeHelper.ts';

function getMavenWidget(): HTMLDivElement | undefined {
  // Close widget to be able to query it by text content
  closeMavenWidget();

  const mavenWidget = Array.from(document.querySelectorAll('div')).find((div) =>
    div.textContent?.includes(MAVEN_AGI.WIDGET_TITLE),
  );

  return mavenWidget;
}

function closeMavenWidget() {
  window?.Maven?.ChatWidget?.close();
}

export function removeMavenWidget() {
  const mavenWidget = getMavenWidget();

  if (mavenWidget) {
    mavenWidget.remove();
    document.body.classList.remove(MAVEN_AGI.CSS_CLASS);
  }
}

export function addMavenWidget(pathname: string) {
  const mavenWidget = getMavenWidget();

  if (!ENABLE_MAVEN_WIDGET || mavenWidget) {
    return;
  }

  const allowedPaths = [
    routes.CONTACT,
    routes.HOME,
    routes.MY_SCHEDULED_PAYMENTS,
    routes.MY_PAYMENT_METHODS,
    routes.MY_PAYMENTS,
    routes.MY_DOCUMENTS,
  ];
  const isAllowedPath = allowedPaths.includes(pathname);

  if (isAllowedPath) {
    document.body.classList.add(MAVEN_AGI.CSS_CLASS);
  }

  const script = document.createElement('script');
  script.src = MAVEN_SCRIPT_URL;
  script.defer = true;

  document.body.appendChild(script);

  script.onload = () => {
    if (window.Maven && window.Maven.ChatWidget) {
      window.Maven.ChatWidget.load({
        orgFriendlyId: 'scratchpay',
        agentFriendlyId: 'borrowers',
        textColor: 'white',
        bgColor: '#5B70FF',
      });
    }
  };

  return () => {
    removeMavenWidget();

    document.body.removeChild(script);
  };
}
