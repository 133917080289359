import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import classNames from 'classnames';

import { routes } from 'utils/routeHelper';

import { IconBack, IconClose } from 'assets/icons';
import { ElevationAppbar } from 'components/widgets';
import { IStyleOptions } from 'components/widgets/ElevationAppbar';

import './Header.scss';
import { removeMavenWidget } from '../../../utils/maven.ts';

const DEFAULT_LEVEL_BY_ROUTE = {
  [routes.ROOT]: 0,
  [routes.LOGIN]: 0,
  [routes.OTP_CONFIRMATION]: 1,
  [routes.HOME]: 0, // Reset level after being signed in
  [routes.USER_PROFILE]: 1,
  [routes.CONTACT]: 1,
  [routes.MY_LOANS]: 1,
  [routes.MY_PAYMENT_METHODS]: 1,
  [routes.MY_PAYMENTS]: 1,
  [routes.MY_SCHEDULED_PAYMENTS]: 1,
  [routes.LOAN_OVERVIEW]: 1,
  [routes.PAYMENT_DETAILS]: 1,
  [routes.PAYMENT_METHOD_DETAILS]: 1,
  [routes.PAYMENT_OVERVIEW]: 2,
  [routes.PAYMENT_METHOD_OVERVIEW]: 2,
  [routes.MY_DOCUMENTS]: 1,
  [routes.EFT_AGREEMENT]: 1,
  [routes.AUTOPAY_AUTH_TERMS]: 1,
  [routes.SELECT_PAYMENT_METHOD]: 1,
};

export interface IHeaderProps {
  onBack?: () => void;
  styleOptions?: IStyleOptions;
}

const getRouteLevel = (route: string) => {
  let level = DEFAULT_LEVEL_BY_ROUTE[route];
  if (level === undefined) {
    level = route.split('/').length >= 3 ? 1 : 0;
  }
  return level;
};

const Header = (props: IHeaderProps) => {
  const { styleOptions } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const level = getRouteLevel(location.pathname);
  const isBackVisible = level >= 1;
  const isCloseVisible = level === 2;

  const handleOnGoBack = () => {
    removeMavenWidget();

    if (props.onBack) {
      // Used for Confirmation Code page
      props.onBack();
      return;
    }

    if (location.key === 'default') {
      navigate(routes.HOME);
      return;
    }

    navigate(-1);
  };

  const handleOnClose = () => {
    navigate(routes.HOME);
  };
  const colorScheme = {
    dark: styleOptions && styleOptions.isDarkBlue,
    light: styleOptions && styleOptions.isLight,
  };

  return (
    <ElevationAppbar styleOptions={styleOptions}>
      <Fragment>
        {isBackVisible && (
          <IconButton onClick={handleOnGoBack} styleName={classNames('btn icon-back', colorScheme)} size="large">
            <IconBack />
            <span styleName="icon-text">Back</span>
          </IconButton>
        )}
        {isCloseVisible && (
          <IconButton onClick={handleOnClose} styleName={classNames('btn icon-close', colorScheme)} size="large">
            <IconClose />
          </IconButton>
        )}
      </Fragment>
    </ElevationAppbar>
  );
};

export default Header;
