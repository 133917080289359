import { useState } from 'react';
import { isNil } from 'lodash';
import { loadStripe, PaymentMethod as StripePaymentMethod, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { IAddPaymentMethodRegularData } from 'actions/paymentMethod';
import { styleColors } from 'utils/theme';
import { STRIPE_PUBLIC_KEY, PAYMENT_METHOD_TYPES, PAYMENT_METHOD_VENDORS } from 'utils/constants';
import { formatExpiryDateFromStripe } from 'utils/format';

import { Fab, TextInput } from 'components/widgets';

import './StripeCardForm.scss';

let stripePromise: Promise<any>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  }
  return stripePromise;
};

export const buildPaymentMethodResult = (
  name: string,
  paymentMethod: StripePaymentMethod,
): IAddPaymentMethodRegularData => {
  const { brand, last4, exp_month, exp_year } = paymentMethod.card!;
  const { postal_code } = paymentMethod.billing_details.address!;
  const expiry = formatExpiryDateFromStripe(exp_year, exp_month);
  const result = {
    token: paymentMethod.id,
    paymentMethod: PAYMENT_METHOD_TYPES.CARD,
    name,
    last4,
    expiry,
    type: brand,
    vendor: PAYMENT_METHOD_VENDORS.STRIPE,
    billingZip: postal_code || undefined,
  };
  return result;
};

interface ICardFormProps {
  isAdding: boolean;
  onAddedSuccess: (result: IAddPaymentMethodRegularData) => void;
  onAddedError: (error: any) => void;
}

const CardForm = (props: ICardFormProps) => {
  const { isAdding, onAddedSuccess, onAddedError } = props;
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [completeCard, setCompleteCard] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const onAddPaymentMethod = async (event: any) => {
    event.preventDefault();

    const cardElement = elements!.getElement(CardElement);
    if (!cardElement) return;

    try {
      const { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { name },
      });
      if (!isNil(error)) throw error;

      const result = buildPaymentMethodResult(name, paymentMethod!);
      onAddedSuccess(result);
    } catch (error) {
      onAddedError(error);
    }
  };

  const onChangeCardData = (event: StripeCardElementChangeEvent) => {
    const { error, complete } = event;
    if (!isNil(error)) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('');
      setCompleteCard(complete);
    }
  };

  const isAddCardButtonDisabled = () => {
    return isAdding || !name || !completeCard;
  };

  return (
    <>
      <TextInput label="Card holder name" value={name} onChange={setName} />
      <CardElement
        onChange={onChangeCardData}
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: styleColors.textDefault,
              '::placeholder': {
                color: styleColors.grey1000,
              },
            },
            invalid: {
              color: styleColors.red100,
            },
          },
        }}
      />
      {errorMessage && <div styleName="error-message">{errorMessage}</div>}
      <Fab disabled={isAddCardButtonDisabled()} onClick={onAddPaymentMethod}>
        <span>Add card</span>
      </Fab>
    </>
  );
};

const StripeCardForm = (props: ICardFormProps) => {
  return (
    <Elements stripe={getStripe()}>
      <CardForm {...props} />
    </Elements>
  );
};

export default StripeCardForm;
