import { select, takeEvery, call, put } from 'redux-saga/effects';

import * as apis from 'apis';
import { isSynchronizingSelector } from 'selectors/loan';
import {
  IToggleAutopayData,
  ISetPrimaryPaymentMethodData,
  actionTypes,
  synchronizeLoansRequest,
  synchronizeLoansSuccess,
  synchronizeLoansError,
  toggleAutopaySuccess,
  toggleAutopayError,
  setPrimaryPaymentMethodSuccess,
  setPrimaryPaymentMethodError,
} from 'actions/loan';
import { showNotification } from 'utils/notification';
import { extractErrorMessage } from 'utils/validate';

const TOGGLE_AUTOPAY_NOTIFICATION = {
  ENABLE: {
    SUCCESS: 'Autopay was enabled successfully. Your latest payment method will be charged on next payment due date.',
    ERROR: 'Autopay could not be enabled. Please try again later or contact support if the problem persists.',
  },
  DISABLE: {
    SUCCESS: 'Autopay was disabled successfully.',
    ERROR: 'Autopay could not be disabled. Please try again later or contact support if the problem persists.',
  },
};

const SET_PRIMARY_PAYMENT_METHOD_NOTIFICATION = {
  SUCCESS: 'Set primary payment method successfully',
  ERROR: 'Could not set primary payment method',
};

export function* renewLoans(): Generator {
  const isSynchronizing = yield select(isSynchronizingSelector);
  if (!isSynchronizing) {
    try {
      yield put(synchronizeLoansRequest());
      yield call(apis.renewLoans);
      yield put(synchronizeLoansSuccess());
    } catch (error) {
      yield put(synchronizeLoansError());
    }
  }
}

export function* toggleAutopay(action: IActionSaga<IToggleAutopayData>) {
  const { data, onSuccess, onError } = action.payload;
  const notification = data.isEnabled ? TOGGLE_AUTOPAY_NOTIFICATION.ENABLE : TOGGLE_AUTOPAY_NOTIFICATION.DISABLE;
  try {
    yield call(apis.toggleAutopay, data);
    yield put(toggleAutopaySuccess(data.loanId));
    yield showNotification(notification.SUCCESS, { variant: 'success' });
    if (onSuccess) onSuccess();
  } catch (error) {
    yield put(toggleAutopayError(data.loanId));
    yield showNotification(notification.ERROR, { variant: 'error' });
    if (onError) onError();
  }
}

export function* setPrimaryPaymentMethod(action: IActionSaga<ISetPrimaryPaymentMethodData>) {
  const { data } = action.payload;
  try {
    yield call(apis.setPrimaryPaymentMethod, data);
    yield put(setPrimaryPaymentMethodSuccess(data.loanId));
    yield showNotification(SET_PRIMARY_PAYMENT_METHOD_NOTIFICATION.SUCCESS, { variant: 'success' });
  } catch (error) {
    const extractedErrorMessage = extractErrorMessage(error);
    const errorMessage = `${SET_PRIMARY_PAYMENT_METHOD_NOTIFICATION.ERROR}: ${extractedErrorMessage}`;
    yield put(setPrimaryPaymentMethodError(data.loanId));
    yield showNotification(errorMessage, { variant: 'error' });
  }
}

export default function* loanWatcher() {
  yield takeEvery(actionTypes.RENEW_LOANS, renewLoans);
  yield takeEvery(actionTypes.TOGGLE_AUTOPAY, toggleAutopay);
  yield takeEvery(actionTypes.SET_PRIMARY_PAYMENT_METHOD, setPrimaryPaymentMethod);
}
