import Col from '../Col';
import './AutopayAuthTermsBody.scss';
import { externalLinks } from 'utils/routeHelper';
import AutopayAuthInfo from './AutopayAuthInfo';

const AutopayAuthTermsBody = (props: IAutopayAuth) => {
  const { paymentAmount, paymentStartDate, paymentMethodLast4, remainingInstallments, frequency } = props;

  return (
    <div styleName="wrapper">
      <h1>Autopay Authorization Terms</h1>
      <h4>Details:</h4>
      <Col styleName="details">
        <AutopayAuthInfo text="Payment Amount: *" value={paymentAmount} />
        <AutopayAuthInfo text="Payment Start Date: **" value={paymentStartDate} />
        <AutopayAuthInfo text="Payment Method Ending in: " value={paymentMethodLast4} />
        <AutopayAuthInfo text="# of Recurring Payments: " value={remainingInstallments} />
        <AutopayAuthInfo text="Frequency:  " value={frequency} />
        <p>
          By clicking on <strong>“Submit”</strong> I agree to each of the following:
        </p>
        <p>
          I hereby authorize Scratch Financial ({externalLinks.HOME}) to initiate an electronic withdrawal from the bank
          account or an electronic transaction to the debit card set forth above, in such amount and on such date as set
          forth above. If I am setting up a payment schedule (recurring transactions), I authorize the electronic bank
          account withdrawals or debit card transactions for the amount and with such frequency set forth above.
        </p>
        <p>
          If the payment (or if I am authorizing a payment schedule, any future payment) is rejected by my financial
          institution or card issuer for any reason, including without limitation insufficient funds, I understand and
          agree that Scratch Financial may in its discretion attempt to process that payment again within 30 days. I
          also agree that an NSF charge may apply and be charged separately.
        </p>
        <p>
          If I am setting up a payment schedule, I hereby agree to notify Scratch Financial of any termination of this
          authorization, or of any changes in my bank account or debit card information, at least one (1) business day
          prior to the next scheduled payment due date. I understand that to revoke this authorization or to notify
          Scratch Financial of any account changes, I should call 855-727-2395 during its normal business hours (8AM TO
          8PM PST). I agree that this is a recurring payment event that corresponds to the terms set forth above, and
          the amount drafted corresponds to my contractual payment amount, which may change due to accrued late fees or
          unpaid NSF charges (which will appear on my account statements).
        </p>
        <p>
          I acknowledge that the origination of electronic fund transactions to or from my account must comply with all
          applicable provisions of U.S. law and the NACHA rules (as applicable). I also assert that I am the owner of or
          an authorized signatory on the bank account or that I am the card account holder or an authorized user, as
          applicable.
        </p>
      </Col>

      <Col styleName="disclaimer">
        <p>
          * In the event your Amount Financed is less than that stated in your Loan Agreement we may LOWER your monthly
          Payment Amount stated above.
        </p>
        <p>
          ** In the event funds associated with your Loan Agreement are transferred after the date you executed your
          Loan Agreement, we may base your Payment Start Date on the later date.
        </p>
      </Col>
    </div>
  );
};

export default AutopayAuthTermsBody;
