import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import store from 'store';
import { logout } from 'actions/user';
import { setInternalServerErrorDialogVisible } from 'reducers/internalServerErrorDialogSlice';
import firebaseClient from 'utils/firebase';
import { getAuth } from 'firebase/auth';
import { captureException } from 'utils/sentry';
import { INTERMEDIARY_API_PATH } from 'utils/constants';

const interceptRequest = async (config: AxiosRequestConfig) => {
  const { currentUser } = await getAuth(firebaseClient);

  if (currentUser) {
    const token = await currentUser.getIdToken();

    const bearerString = `Bearer ${token}`;
    if (config.headers) {
      config.headers.Authorization = bearerString;
    }
  }
  return config;
};

const intercepResponseError = async (error: AxiosError<{ status: number }>) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
      case 403:
        store.dispatch(logout());
        break;
      case 500:
        captureException(error);
        store.dispatch(setInternalServerErrorDialogVisible(true));
        break;
      default:
        break;
    }
  }
  return Promise.reject(error);
};

const axiosClient = axios.create({
  baseURL: INTERMEDIARY_API_PATH,
  headers: {
    Accept: 'application/json',
  },
});
axiosClient.defaults.headers.post['Content-Type'] = 'appplication/json';
axiosClient.interceptors.request.use(interceptRequest);
axiosClient.interceptors.response.use((response) => response.data, intercepResponseError);

export default axiosClient;
