import { ComponentProps } from 'react';
import { ActionButton, CancelLink } from 'components/widgets';

import './UserDecisionWrapper.scss';

interface IProps {
  buttonText: string;
  buttonProps?: Omit<Partial<ComponentProps<typeof ActionButton>>, 'customVariant'>;
  linkProps?: Partial<ComponentProps<typeof CancelLink>>;
}

const UserDecisionWrapper = (props: IProps) => {
  const { buttonText, buttonProps, linkProps } = props;

  return (
    <div styleName="wrapper">
      <ActionButton customVariant="standard" {...buttonProps}>
        {buttonText}
      </ActionButton>
      <CancelLink {...linkProps} />
    </div>
  );
};

export default UserDecisionWrapper;
