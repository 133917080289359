import { actionTypes } from 'actions/convenienceFee';

export const initialState: IConvenienceFeeState = {
  isFetchingConvenienceFee: false,
  isFetchedConvenienceFee: false,
  convenienceFee: {
    value: 0,
    unit: 100,
    currency: 'USD',
  },
};

export default function convenienceFeeReducer(
  state: IConvenienceFeeState = initialState,
  action: IAction<any>
): IConvenienceFeeState {
  switch (action.type) {
    case actionTypes.GET_CONVENIENCE_FEE_REQUEST: {
      return {
        ...state,
        isFetchingConvenienceFee: true,
      };
    }
    case actionTypes.GET_CONVENIENCE_FEE_SUCCESS: {
      const { payload: convenienceFee } = action;
      return {
        ...state,
        isFetchingConvenienceFee: false,
        isFetchedConvenienceFee: true,
        convenienceFee,
      };
    }
    case actionTypes.GET_CONVENIENCE_FEE_ERROR: {
      return {
        ...state,
        isFetchingConvenienceFee: false,
        isFetchedConvenienceFee: false,
      };
    }
    default:
      return state;
  }
}
