import { createAction, Action, BaseAction } from 'redux-actions';

export const actionTypes = {
  GET_CONVENIENCE_FEE: 'GET_CONVENIENCE_FEE',
  GET_CONVENIENCE_FEE_REQUEST: 'GET_CONVENIENCE_FEE_REQUEST',
  GET_CONVENIENCE_FEE_SUCCESS: 'GET_CONVENIENCE_FEE_SUCCESS',
  GET_CONVENIENCE_FEE_ERROR: 'GET_CONVENIENCE_FEE_ERROR',
};

export const getConvenienceFee: (payload: IConvenienceFeeFactorsPayload) => BaseAction = createAction(
  actionTypes.GET_CONVENIENCE_FEE
);

export interface IConvenienceFeeFactorsPayload {
  data: IConvenienceFeeFactors;
}

export const getConvenienceFeeRequest: () => BaseAction = createAction(actionTypes.GET_CONVENIENCE_FEE_REQUEST);

export const getConvenienceFeeSuccess: (convenienceFee: IConvenienceFee) => Action<IConvenienceFee> = createAction(
  actionTypes.GET_CONVENIENCE_FEE_SUCCESS
);

export const getConvenienceFeeError: () => Action<void> = createAction(actionTypes.GET_CONVENIENCE_FEE_ERROR);
