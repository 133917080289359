import { SetStateAction } from 'react';
import { Modal } from '@mui/material';
import './ConvenienceFeeModal.scss';
import { formatAmount } from 'utils/format';
import { Link } from 'react-router-dom';

export interface IProps {
  show: boolean;
  setShow: (value: SetStateAction<boolean>) => void;
  convenienceFeeAmount: number;
  isAutopayEnabled: boolean;
}

const ConvenienceFeeModal = ({ show, setShow, convenienceFeeAmount, isAutopayEnabled }: IProps) => (
  <Modal open={show} onClose={() => setShow(false)}>
    <div styleName="modal-wrapper">
      <h1>Convenience Fees</h1>
      <p>
        When you pay with a card, a {formatAmount(convenienceFeeAmount)} Convenience Fee will be added. You can avoid
        this fee by paying with your bank account (ACH) or setting up Autopay.
      </p>
      <div styleName="action">
        <Link to="/my-payment-methods">Add Bank Account (ACH)</Link>
      </div>
      {!isAutopayEnabled && (
        <div styleName="action">
          <Link to="/autopay-activation">Set Up Autopay</Link>
        </div>
      )}
    </div>
  </Modal>
);

export default ConvenienceFeeModal;
