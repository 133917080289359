import { useLocation } from 'react-router-dom';
import { IconScratchpayHeart } from 'assets/icons';
import { Footer, Header } from 'components/layouts';
import { Col, AutopayAuthTermsBody, IconWrapper } from 'components/widgets';
import './AutopayAuthTerms.scss';

export interface AutopayAuthTermsState {
  autopayAuthTerms: IAutopayAuth;
}

const AutopayAuthTerms = () => {
  const location = useLocation();
  const { autopayAuthTerms } = location.state;

  return (
    <div styleName="wrapper">
      <Header styleOptions={{ isSticky: false, isLight: true }} />
      <Col xs={12} sm={6} md={4} styleName="col">
        <IconWrapper>
          <IconScratchpayHeart />
        </IconWrapper>
        <AutopayAuthTermsBody {...autopayAuthTerms} />
      </Col>
      <Footer />
    </div>
  );
};

export default AutopayAuthTerms;
