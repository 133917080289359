import { createPortal } from 'react-dom';
import { GifProcessingAnimation } from '../../assets/animations';

import './GifAnimationWrapper.scss';

interface Props {
  src?: string;
}

const GifAnimationWrapper = (props: Props) => {
  let { src = GifProcessingAnimation }: Props = props;
  if (!src.slice(-4).includes('.gif')) {
    src = GifProcessingAnimation;
  }

  return (
    <>
      {createPortal(
        <div styleName="wrapper">
          <img styleName="animation" src={src} alt="Loading animation..." />
        </div>,
        document.body,
      )}
    </>
  );
};

export default GifAnimationWrapper;
