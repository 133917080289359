import './AutopayAuthInfo.scss';

export interface IAutopayAuthInfoProps {
  text: string;
  value: string | number;
}

const AutopayAuthInfo = (props: IAutopayAuthInfoProps) => {
  let { text, value } = props;
  if (!value) {
    value = 'N/A';
  }

  return (
    <div styleName="wrapper">
      <p>{text}</p>
      <p>
        <strong>{value}</strong>
      </p>
    </div>
  );
};

export default AutopayAuthInfo;
