import { setSelectedLoanId } from 'actions/loan';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as loanSelectors from 'selectors/loan';
import { CLIENT_STORAGE_KEY } from 'utils/constants';
import useClientStorage from './useClientStorage';
import { useAppDispatch } from 'redux-hooks';

const useSelectedLoan = () => {
  const dispatch = useAppDispatch();
  const { uniqueId: paramsSelectedLoanId } = useParams();
  const { getItem: getSelectedLoanIdStorageItem, handleSetStorageItem } = useClientStorage(
    'local',
    CLIENT_STORAGE_KEY.SELECTED_LOAN_ID,
  );
  const selectedLoan = useSelector(loanSelectors.selectedLoanSelector);
  const selectedLoanIdFromState = useSelector(loanSelectors.selectedLoanIdSelector);
  const selectedLoanIdStorageItem = getSelectedLoanIdStorageItem();
  const selectedLoanIdStored =
    (typeof paramsSelectedLoanId === 'string' && paramsSelectedLoanId) ||
    (typeof selectedLoanIdStorageItem === 'string' && selectedLoanIdStorageItem) ||
    null;
  if (selectedLoanIdStored && !selectedLoanIdFromState) {
    dispatch(setSelectedLoanId(selectedLoanIdStored));
  }

  const selectedLoanId = selectedLoan && selectedLoan.id;
  useEffect(() => {
    if (selectedLoanId) {
      // override local storage selected loan id
      handleSetStorageItem(selectedLoanId);
    }
  }, [handleSetStorageItem, selectedLoanId]);

  return selectedLoan;
};

export default useSelectedLoan;
