import classNames from 'classnames';

import './FindPlanButton.scss';
import { useApplicationUrlCreator } from 'hooks';
import { Button } from '@mui/material';

interface IFindPlanButtonProps {
  align?: 'center' | 'left' | 'right';
  color?: 'colored' | 'transparent';
  label?: string;
}

const FindPlanButton = ({ align = 'left', color = 'transparent', label = 'Find a Plan' }: IFindPlanButtonProps) => {
  const { isCreatingApplicationUrl, createApplicationUrl } = useApplicationUrlCreator();
  const handleFindPaymentPlan = () => createApplicationUrl();

  return (
    <div
      styleName={classNames('wrapper', { [`btn-${align}`]: true }, { [`btn-transparent`]: color === 'transparent' })}
    >
      <Button disabled={isCreatingApplicationUrl} onClick={handleFindPaymentPlan} variant="contained">
        {isCreatingApplicationUrl ? 'Finding...' : label}
      </Button>
    </div>
  );
};

export default FindPlanButton;
