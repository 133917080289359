import { useEffect, useState } from 'react';

import firebaseClient from 'utils/firebase';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { configureScopeWithUserId } from 'utils/sentry';

export type ScratchAuth = {
  isChecked: boolean;
  scratchBorrowerId: string;
};
const SIGNED_OUT_USER: ScratchAuth = {
  isChecked: true,
  scratchBorrowerId: '',
};

const useAuthenticationSubscriber = () => {
  const authFirebase = getAuth(firebaseClient);
  const analytics = getAnalytics(firebaseClient);
  const [auth, setAuth] = useState<ScratchAuth>({ ...SIGNED_OUT_USER, isChecked: false });

  useEffect(() => {
    const unsubscriber = authFirebase.onAuthStateChanged((user) => {
      if (user) {
        setAuth({
          isChecked: true,
          scratchBorrowerId: user.uid,
        });
        if (user) {
          setUserId(analytics, user.uid);
          configureScopeWithUserId(user.uid);
          if (window.heap) {
            window.heap.identify(user.uid);
          }
        }
      } else {
        setAuth(SIGNED_OUT_USER);
      }
    });
    return unsubscriber;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return auth;
};

export default useAuthenticationSubscriber;
