import { useSelector } from 'react-redux';

import * as userAction from 'actions/user';
import { isCreatingApplicationUrlSelector } from 'selectors/user';
import { SCRATCHPAY_URL, isCordovaIOSApp, isCordovaAndroidApp } from 'utils/constants';
import { redirectToExternalUrl } from 'utils/routeHelper';
import { useAppDispatch } from 'redux-hooks';

const useApplicationUrlCreator = () => {
  const dispatch = useAppDispatch();
  const isCreatingApplicationUrl = useSelector(isCreatingApplicationUrlSelector);

  const createApplicationUrl = () => {
    // this method was simplified to just redirect to the borrower-service
    // app, but in Android, this should redirect to a /verify url and 
    // allow the user to directly apply for a new loan
    // see https://scratchpay.atlassian.net/browse/OR-4399
    if (isCordovaIOSApp || isCordovaAndroidApp) {
      redirectToExternalUrl(SCRATCHPAY_URL);
    } else if (!isCreatingApplicationUrl) {
      dispatch(userAction.createApplicationUrl());
    }
  };

  return { isCreatingApplicationUrl, createApplicationUrl };
};

export default useApplicationUrlCreator;
