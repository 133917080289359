import { useCallback, useEffect, useState } from 'react';

import { Dialog } from 'components/widgets';

import './BetaModeTurnDownDialog.scss';

const HOSTNAME_BETA = 'beta.scratchpay.com';
const HOSTNAME_OFFICIAL = 'account.scratchpay.com';
const REDIRECT_TIMEOUT = 3 * 1000;

const BetaModeTurnDownDialog = () => {
  const [redirectUrl, setRedirectUrl] = useState('');
  const isShowBetaModeTurnDownDialog = !!redirectUrl;

  const redirectToOfficialSite = useCallback(() => {
    window.location.replace(redirectUrl!);
  }, [redirectUrl]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const isBeta = url.hostname.includes(HOSTNAME_BETA);
    if (isBeta) {
      url.hostname = HOSTNAME_OFFICIAL;
      setRedirectUrl(url.href);
    }
  }, []);

  useEffect(() => {
    let timeoutId: any;
    if (redirectUrl) {
      timeoutId = setTimeout(redirectToOfficialSite, REDIRECT_TIMEOUT);
    }
    return () => clearTimeout(timeoutId);
  }, [redirectToOfficialSite, redirectUrl]);

  return (
    <Dialog
      isOpen={isShowBetaModeTurnDownDialog}
      title="Scratchpay user account is no longer in Beta mode"
      actions={[
        {
          text: 'OK',
          onClick: redirectToOfficialSite,
        },
      ]}
    >
      <div styleName="content">
        <span>You can now visit your account at&nbsp;</span>
        <a href={redirectUrl} styleName="official-url">
          {HOSTNAME_OFFICIAL}
        </a>
        <span>. If you do nothing you will be redirected automatically after 3 seconds.</span>
      </div>
    </Dialog>
  );
};

export default BetaModeTurnDownDialog;
