import { useCallback, useEffect } from 'react';

import { getPayments } from 'actions/payment';
import { useAuthenticationSubscriber } from 'hooks';
import { useAppDispatch } from 'redux-hooks';

const usePaymentFetcher = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAuthenticationSubscriber();

  const fetchPayments = useCallback(() => dispatch(getPayments()), [dispatch]);

  useEffect(() => {
    if (currentUser.scratchBorrowerId) {
      fetchPayments();
    }
  }, [currentUser, fetchPayments]);

  return { fetchPayments };
};

export default usePaymentFetcher;
