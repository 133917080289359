import { useCallback, useEffect } from 'react';

import { renewLoans } from 'actions/loan';
import { useAuthenticationSubscriber } from 'hooks';
import { useAppDispatch } from 'redux-hooks';

const useLoanAndScheduledPaymentFetcher = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAuthenticationSubscriber();

  const fetchLoansAndScheduledPayments = useCallback(() => dispatch(renewLoans()), [dispatch]);

  useEffect(() => {
    if (currentUser.scratchBorrowerId) {
      fetchLoansAndScheduledPayments();
    }
  }, [currentUser, fetchLoansAndScheduledPayments]);

  return { fetchLoansAndScheduledPayments };
};

export default useLoanAndScheduledPaymentFetcher;
