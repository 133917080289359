interface IdfaInterface {
  trackingLimited: boolean;
  idfa: string;
  trackingPermission: number;
}

// For Reference:
// enum TRACKING_PERMISSION {
//   TRACKING_PERMISSION_NOT_DETERMINED=0,
//   TRACKING_PERMISSION_RESTRICTED=1,
//   TRACKING_PERMISSION_DENIED=2,
//   TRACKING_PERMISSION_AUTHORIZED=3,
// }

export const initializeATT = () => {
  const idfaPlugin = window.cordova.plugins.idfa;
  idfaPlugin.getInfo().then((info: IdfaInterface) => {
    if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
      return idfaPlugin.requestPermission();
    }
  });
};
