const environments = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
  TEST: 'test',
};

class EnvironmentHelper {
  get environments() {
    return environments;
  }

  static isDevelopment(env: string) {
    return env !== environments.PRODUCTION && env !== environments.STAGING;
  }
}

export { EnvironmentHelper };
