import { useCallback, useEffect } from 'react';

import { getDocuments } from 'actions/documents';
import { useAuthenticationSubscriber } from 'hooks';
import { useAppDispatch } from 'redux-hooks';

const useDocumentsFetcher = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAuthenticationSubscriber();

  const fetchDocuments = useCallback(() => dispatch(getDocuments()), [dispatch]);

  useEffect(() => {
    if (currentUser.scratchBorrowerId) {
      fetchDocuments();
    }
  }, [currentUser, fetchDocuments]);

  return { fetchDocuments };
};

export default useDocumentsFetcher;
