import { styled } from '@mui/material/styles';
import { Box, Switch } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch';

import { styleColors } from 'utils/theme';

interface IProps extends SwitchProps {
  isEnabled: boolean;
}

const CustomizedSwitch = styled(Switch)({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 54,
    height: 26,
    padding: 0,
    marginLeft: 12,
  },
  switchBase: {
    '&.Mui-checked $thumb': {
      background: styleColors.white,
    },
    '&.Mui-checked + $track': {
      background: styleColors.midnight50,
    },
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      color: styleColors.midnight50,
      '& + $track': {
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 21,
    height: 21,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${styleColors.grey1200}`,
    backgroundColor: styleColors.grey1200,
    opacity: 1,
  },
  checked: {},
  wrapper: {},
  enabled: {},
});

const AutopayToggle = ({ isEnabled, ...rest }: IProps) => {
  const wrapperClasses = `wrapper ${isEnabled ? 'enabled' : ''}`;

  return (
    <Box className={wrapperClasses}>
      <CustomizedSwitch disableRipple {...rest} checked={isEnabled} />
    </Box>
  );
};

export default AutopayToggle;
