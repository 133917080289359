import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as apis from 'apis';
import { getDocumentsRequest, getDocumentsSuccess, getDocumentsError, actionTypes } from 'actions/documents';
import { isFetchingDocumentsSelector } from 'selectors/documents';
import { BaseAction } from 'redux-actions';

export function* getDocuments(): Generator<BaseAction, void, any> {
  const isFetching = yield select(isFetchingDocumentsSelector);
  if (!isFetching) {
    try {
      yield put(getDocumentsRequest());
      const { result: documents } = yield call(apis.getDocuments);
      yield put(getDocumentsSuccess(documents));
    } catch (error) {
      yield put(getDocumentsError());
    }
  }
}

export function* downloadDocument(action: IActionSaga<ILoanDocument>) {
  const { data, onSuccess, onError } = action.payload;
  try {
    const { file } = yield call(apis.downloadDocument, data.link);
    if (onSuccess) onSuccess(file, data);
  } catch (error) {
    if (onError) onError(error, data);
  }
}

export default function* documentsWatcher() {
  yield takeEvery(actionTypes.GET_DOCUMENTS, getDocuments);
  yield takeEvery(actionTypes.DOWNLOAD_DOCUMENT, downloadDocument);
}
