import { useEffect, useRef } from 'react';

import { getCachedBorrowerRequest, getCachedBorrowerSuccess } from 'actions/user';
import { getBorrowerCacheQuery } from 'utils/firebase';
import { onSnapshot } from 'firebase/firestore';

import useBorrowerFetcher from './useBorrowerFetcher';
import { useAuthenticationSubscriber } from 'hooks';
import { useAppDispatch } from 'redux-hooks';

const useBorrowerSubscriber = () => {
  useBorrowerFetcher();
  const dispatch = useAppDispatch();
  const unsubscriberRef = useRef<Function>();
  const scratchBorrowerId = useAuthenticationSubscriber().scratchBorrowerId;

  const unsubscribe = () => unsubscriberRef.current && unsubscriberRef.current();

  useEffect(() => {
    unsubscribe();
    if (scratchBorrowerId) {
      dispatch(getCachedBorrowerRequest());
      const borrowerCacheQuery = getBorrowerCacheQuery(scratchBorrowerId);
      if (borrowerCacheQuery) {
        unsubscriberRef.current = onSnapshot(borrowerCacheQuery, (querySnapshot) => {
          if (querySnapshot.exists()) {
            const { borrower } = querySnapshot.data() as { borrower: IBorrower };
            dispatch(getCachedBorrowerSuccess(borrower));
          }
        });
      }
    }
    return unsubscribe;
  }, [dispatch, scratchBorrowerId]);
};

export default useBorrowerSubscriber;
