import { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { routes } from 'utils/routeHelper';
import {
  Contact,
  Login,
  OTPConfirmation,
  Home,
  PaymentDetails,
  PaymentMethodDetails,
  LoanOverview,
  PaymentMethodOverview,
  PaymentOverview,
  UserProfile,
  MyLoans,
  MyPaymentMethods,
  MyPayments,
  MyScheduledPayments,
  MyDocuments,
  EFTAgreement,
  MyLoanPaidOff,
  AutopayAuthTerms,
  AutopayActivation,
  SelectPaymentMethod,
  NoMatch404,
} from 'components/pages';
import AuthRoute from './AuthRoute';
import RequireAuth from './PrivateRoute';
import { useAuthenticationSubscriber } from 'hooks';

const RouterContainer: FC = () => {
  const [previousLocation, setPreviousLocation] = useState<any | null>(null);
  const currentUser = useAuthenticationSubscriber();
  const location = useLocation();

  useEffect(() => {
    setPreviousLocation(location);
  }, [location]);

  const withAuth = (Component: FC<any>) => (
    <RequireAuth currentUser={currentUser}>
      <Component from={previousLocation} />
    </RequireAuth>
  );

  const routesConfig = [
    {
      path: routes.LOGIN,
      element: (
        <AuthRoute currentUser={currentUser}>
          <Login />
        </AuthRoute>
      ),
    },
    {
      path: routes.OTP_CONFIRMATION,
      element: (
        <AuthRoute currentUser={currentUser}>
          <OTPConfirmation />
        </AuthRoute>
      ),
    },
    { path: routes.ROOT, element: <Navigate to={routes.HOME} replace /> },
    { path: routes.HOME, element: withAuth(Home) },
    { path: routes.CONTACT, element: <Contact /> },
    { path: routes.PAYMENT_DETAILS, element: withAuth(PaymentDetails) },
    { path: routes.PAYMENT_METHOD_DETAILS, element: withAuth(PaymentMethodDetails) },
    { path: routes.LOAN_OVERVIEW, element: withAuth(LoanOverview) },
    { path: routes.LOAN_OVERVIEW_BY_UNIQUE_ID, element: withAuth(LoanOverview) },
    { path: routes.LOAN_OVERVIEW_BY_UNIQUE_ID_SCHEDULED, element: withAuth(LoanOverview) },
    { path: routes.PAYMENT_METHOD_OVERVIEW, element: withAuth(PaymentMethodOverview) },
    { path: routes.PAYMENT_OVERVIEW, element: withAuth(PaymentOverview) },
    { path: routes.USER_PROFILE, element: withAuth(UserProfile) },
    { path: routes.MY_LOANS, element: withAuth(MyLoans) },
    { path: routes.LOAN_PAID_OFF, element: withAuth(MyLoanPaidOff) },
    { path: routes.MY_PAYMENT_METHODS, element: withAuth(MyPaymentMethods) },
    { path: routes.MY_PAYMENTS, element: withAuth(MyPayments) },
    { path: routes.MY_SCHEDULED_PAYMENTS, element: withAuth(MyScheduledPayments) },
    { path: routes.MY_DOCUMENTS, element: withAuth(MyDocuments) },
    { path: routes.EFT_AGREEMENT, element: withAuth(EFTAgreement) },
    { path: routes.AUTOPAY_AUTH_TERMS, element: withAuth(AutopayAuthTerms) },
    { path: routes.AUTOPAY_ACTIVATION, element: withAuth(AutopayActivation) },
    { path: routes.SELECT_PAYMENT_METHOD, element: withAuth(SelectPaymentMethod) },
    { path: '*', element: <NoMatch404 /> },
  ];

  return (
    <Routes>
      {routesConfig.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
    </Routes>
  );
};

export default RouterContainer;
