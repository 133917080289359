import { getApps, initializeApp } from 'firebase/app';
import { doc, getFirestore, orderBy, query, collection } from 'firebase/firestore';
import { browserSessionPersistence, initializeAuth } from 'firebase/auth';
import { API_KEY, PROJECT_ID, APP_ID, MEASUREMENT_ID, DATABASE_URL } from './constants';

export const firebaseConfig = {
  apiKey: API_KEY,
  projectId: PROJECT_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
  databaseURL: DATABASE_URL,
};

let app: any;
export const initializeFirebase = () => {
  if (!getApps().length) {
    app = initializeApp(firebaseConfig);
    initializeAuth(app, {
      persistence: browserSessionPersistence,
      popupRedirectResolver: undefined,
    });
  }
};

export const getBorrowerCacheQuery = (scratchBorrowerId: string) => {
  if (!app) return;
  const firestore = getFirestore(app);
  return doc(firestore, 'caches', scratchBorrowerId);
};

export const getOrderedLoansQuery = (scratchBorrowerId: string) => {
  if (!app) return;
  const firestore = getFirestore(app);
  const loanCollectionForBorrower = collection(firestore, 'caches', scratchBorrowerId, 'loans');
  return query(loanCollectionForBorrower, orderBy('createdAt', 'desc'));
};

export default app;
