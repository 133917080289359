import { ChangeEvent } from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

import './TextInput.scss';

type Props = Omit<TextFieldProps, 'onChange'> & {
  value?: Nullable<string>;
  errorMessage?: string;
  onChange: (value: string) => void;
  isWhiteStyle?: boolean;
};

const TextInput = (props: Props) => {
  const {
    label,
    disabled,
    value,
    type,
    inputProps,
    errorMessage,
    error,
    onChange,
    onBlur,
    isWhiteStyle = false,
  } = props;

  const handleOnChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  const textFieldProps = {
    value: isNil(value) ? '' : `${value}`,
    type,
    label,
    disabled,
    onBlur,
    onChange: handleOnChange,
    helperText: (error && errorMessage) || '',
    error,
  };

  const customInputProps = {
    type: type === 'tel' ? 'tel' : 'text',
    autoComplete: 'true',
    ...inputProps,
  };

  return (
    <div
      styleName={classNames('wrapper', {
        'white-style': isWhiteStyle,
        disabled,
      })}
    >
      <TextField styleName="text-field" variant="outlined" inputProps={customInputProps} {...textFieldProps} />
    </div>
  );
};

export default TextInput;
