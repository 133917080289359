import { uniq } from 'lodash';
import { normalize, schema } from 'normalizr';

import { actionTypes } from 'actions/payment';

const paymentSchema = new schema.Entity('paymentById');
const paymentListSchema = [paymentSchema];

const scheduledPaymentSchema = new schema.Entity('scheduledPaymentById');
const scheduledPaymentListSchema = [scheduledPaymentSchema];

export const initialState: IPaymentState = {
  paymentById: {},
  paymentIds: [],
  isFetchingPayments: false,
  isFetchedPayments: false,

  scheduledPaymentById: {},
  scheduledPaymentIds: [],

  selectedPaymentId: null,

  selectedPaymentFieldIndex: 0,
};

export default function paymentReducer(state: IPaymentState = initialState, action: IAction<any>): IPaymentState {
  switch (action.type) {
    // Payments
    case actionTypes.GET_PAYMENTS_REQUEST: {
      return {
        ...state,
        isFetchingPayments: true,
      };
    }
    case actionTypes.GET_PAYMENTS_SUCCESS: {
      const { payload: payments } = action;
      const fixingIdPayments = payments.map((payment: IPayment, index: number) => {
        if (payment.id) return payment;
        return {
          ...payment,
          id: `local-${index}`,
        };
      });
      const {
        entities: { paymentById },
        result: paymentIds,
      } = normalize(fixingIdPayments, paymentListSchema);
      return {
        ...state,
        isFetchingPayments: false,
        isFetchedPayments: true,
        paymentById: {
          ...state.paymentById,
          ...paymentById,
        },
        paymentIds,
      };
    }
    case actionTypes.GET_PAYMENTS_ERROR: {
      return {
        ...state,
        isFetchingPayments: false,
        isFetchedPayments: true,
      };
    }

    // Scheduled Payments
    case actionTypes.GET_SCHEDULED_PAYMENTS_SUCCESS: {
      const { payload: scheduledPayments } = action;
      const {
        result: scheduledPaymentIds,
        entities: { scheduledPaymentById },
      } = normalize(scheduledPayments, scheduledPaymentListSchema);
      return {
        ...state,
        scheduledPaymentIds,
        scheduledPaymentById: {
          ...state.scheduledPaymentById,
          ...scheduledPaymentById,
        },
      };
    }
    case actionTypes.GET_LOAN_SCHEDULED_PAYMENTS_SUCCESS: {
      const { payload: scheduledPayments } = action;
      const {
        result: loanScheduledPaymentIds,
        entities: { scheduledPaymentById: loanScheduledPaymentById },
      } = normalize(scheduledPayments, scheduledPaymentListSchema);
      const scheduledPaymentIds = uniq([...state.scheduledPaymentIds, ...loanScheduledPaymentIds]);
      const scheduledPaymentById = {
        ...state.scheduledPaymentById,
        ...loanScheduledPaymentById,
      };
      return {
        ...state,
        scheduledPaymentIds,
        scheduledPaymentById,
      };
    }
    case actionTypes.REMOVE_LOAN_SCHEDULED_PAYMENTS_SUCCESS: {
      const { payload: removedLoanScheduledPayments } = action;
      const removedLoanScheduledPaymentIds = (removedLoanScheduledPayments as IScheduledPayment[]).map(({ id }) => id);
      const scheduledPaymentIds = state.scheduledPaymentIds.filter(
        (id) => !removedLoanScheduledPaymentIds.includes(id)
      );
      const scheduledPaymentById = { ...state.scheduledPaymentById };
      removedLoanScheduledPaymentIds.forEach((id) => delete scheduledPaymentById[id]);
      return {
        ...state,
        scheduledPaymentIds,
        scheduledPaymentById,
      };
    }

    // Selected Payment
    case actionTypes.SELECT_PAYMENT: {
      return {
        ...state,
        selectedPaymentId: action.payload,
      };
    }

    case actionTypes.SET_SELECTED_PAYMENT_FIELD_INDEX: {
      return {
        ...state,
        selectedPaymentFieldIndex: action.payload,
      };
    }

    default:
      return state;
  }
}
