import { ReactNode, useState } from 'react';
import { Tabs, Tab } from '@mui/material';

import { ILoanOverviewValue } from 'utils/loan';

import LoanOverviewTabScheduled from './LoanOverviewTabScheduled';
import LoanOverviewTabStatements from './LoanOverviewTabStatements';
import LoanOverviewTabDetails from './LoanOverviewTabDetails';
import Col from '../Col';

import './LoanOverviewTabs.scss';

const TAB_INDEX = {
  NEXT_PAYMENTS: 0,
  STATEMENTS: 1,
  DETAILS: 2,
};

const TabPanel = (props: { children?: ReactNode; tabIndex: number; activeTabIndex: number }) => {
  const { children, activeTabIndex, tabIndex, ...rest } = props;
  const isActive = tabIndex === activeTabIndex;

  return (
    <div hidden={!isActive} {...rest}>
      {isActive && children}
    </div>
  );
};

interface IProps {
  isFetching: boolean;
  loanId: string;
  isPaidoff: boolean;
  details: ILoanOverviewValue;
}

const LoanOverviewTabs = (props: IProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(TAB_INDEX.NEXT_PAYMENTS);
  const { isFetching, loanId, isPaidoff, details } = props;

  const handleOnChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    if (newTabIndex === activeTabIndex) {
      return;
    }
    setActiveTabIndex(newTabIndex);
  };

  return (
    <>
      <div styleName="wrapper-grey-bg">
        <Col xs={12} sm={6} md={4} styleName="col">
          <Tabs
            styleName="wrapper-tabs"
            value={activeTabIndex}
            onChange={handleOnChange}
            TabIndicatorProps={{ children: <span styleName="tab-indicator" /> }}
          >
            <Tab disableRipple label="Next Payments" sx={{ textTransform: 'none' }} />
            <Tab disableRipple label="Statements" />
            <Tab disableRipple label="Details" />
          </Tabs>
        </Col>
      </div>
      <div styleName="wrapper-tab-panels">
        <TabPanel activeTabIndex={activeTabIndex} tabIndex={TAB_INDEX.NEXT_PAYMENTS}>
          <LoanOverviewTabScheduled loanId={loanId} />
        </TabPanel>
        <TabPanel activeTabIndex={activeTabIndex} tabIndex={TAB_INDEX.STATEMENTS}>
          <LoanOverviewTabStatements loanId={loanId} />
        </TabPanel>
        <TabPanel activeTabIndex={activeTabIndex} tabIndex={TAB_INDEX.DETAILS}>
          <LoanOverviewTabDetails isFetching={isFetching} isPaidoff={isPaidoff} details={details} />
        </TabPanel>
      </div>
    </>
  );
};

export default LoanOverviewTabs;
