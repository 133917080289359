import { omit } from 'lodash';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';

const UNEXPECTED_SPECIAL_REGEX = /[\u202C]/;

const makeRawPhoneNumber = (value: string): string => {
  const countryCodes = ['+1'];
  let cleanedValue = value
    .replace(UNEXPECTED_SPECIAL_REGEX, '')
    .replace(/\s+/g, '')
    .replace(/[^\d+]/g, '');

  for (const code of countryCodes) {
    const escapedCode = code.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`^${escapedCode}`, 'i');
    cleanedValue = cleanedValue.replace(regex, '');
  }

  return cleanedValue;
};

const formatPhoneNumber = (rawValue: string): string => {
  const valueWithoutSeparators = makeRawPhoneNumber(rawValue).slice(0, 20);

  if (valueWithoutSeparators.length === 0) {
    return '';
  }

  let result =
    valueWithoutSeparators.length > 3 ? `(${valueWithoutSeparators.slice(0, 3)}) ` : `(${valueWithoutSeparators}`;

  result +=
    valueWithoutSeparators.length > 6
      ? `${valueWithoutSeparators.slice(3, 6)}-${valueWithoutSeparators.slice(6)}`
      : valueWithoutSeparators.slice(3);

  return result;
};

const TextMaskPhoneNumber = forwardRef<HTMLInputElement, Partial<InputBaseComponentProps>>((props, ref) => {
  const { inputRef, value: propValue, onChange, ...otherProps } = props;
  const localRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const previousValueRef = useRef<string>('');

  useImperativeHandle(ref, () => localRef.current as HTMLInputElement);

  useEffect(() => {
    if (inputRef && localRef.current) {
      inputRef(localRef.current);
    }
  }, [inputRef]);

  useEffect(() => {
    setValue(formatPhoneNumber(makeRawPhoneNumber(propValue || '')));
  }, [propValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const rawValue = makeRawPhoneNumber(input.value);
    const formattedValue = formatPhoneNumber(rawValue);
    const previousFormattedValue = previousValueRef.current;

    previousValueRef.current = formattedValue;

    const cursorPosition = input.selectionStart ?? 0;

    setValue(formattedValue);
    requestAnimationFrame(() => {
      let newCursorPosition = cursorPosition;

      if (formattedValue.length === 2 && cursorPosition === 1) {
        newCursorPosition = 2;
      } else if (formattedValue.length === 7 && cursorPosition === 5) {
        newCursorPosition = 7;
      } else if (formattedValue.length === 11 && cursorPosition === 10) {
        newCursorPosition = 11;
      }
      if (previousFormattedValue.length === 3 && cursorPosition === 1) {
        newCursorPosition = cursorPosition;
      }

      if (formattedValue === previousFormattedValue && cursorPosition > 1) {
        newCursorPosition = cursorPosition - 1;
      }

      if (localRef.current) {
        localRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    });

    if (onChange) {
      onChange({
        target: {
          value: formattedValue,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <input
      {...omit(otherProps, ['areaCode'])}
      ref={localRef}
      value={value}
      onChange={handleChange}
      placeholder={'(XXX) YYY-ZZZZ'}
    />
  );
});

export default TextMaskPhoneNumber;
