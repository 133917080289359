import { useSelector } from 'react-redux';
import { List, ListItem } from '@mui/material';

import { useLoanAndScheduledPaymentFetcher } from 'hooks';
import * as loanSelectors from 'selectors/loan';
import { scheduledPaymentsSelector } from 'selectors/payment';
import { formatAmount, formatDate } from 'utils/format';
import { checkIsPaidScheduledPayment, checkIsDueScheduledPayment } from 'utils/payment';

import { Header } from 'components/layouts';
import { Col, LoadingSpinner } from 'components/widgets';
import './MyScheduledPayments.scss';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { addMavenWidget } from '../../utils/maven.ts';

const MyScheduledPayments = () => {
  const location = useLocation();

  useLoanAndScheduledPaymentFetcher();
  const scheduledPayments = useSelector(scheduledPaymentsSelector);
  const isFetching = useSelector(loanSelectors.isFetchingOrSynchingSelector);

  useEffect(() => {
    addMavenWidget(location.pathname);
  }, [location.pathname]);

  const renderNoScheduledPayment = () => (
    <div styleName="no-data-wrapper">
      You don't have any scheduled payment yet. This is where you will see the list of all your scheduled payments.
    </div>
  );

  const renderScheduledPaymentItem = (scheduledPayment: IScheduledPayment) => {
    const { id, dueAt } = scheduledPayment;
    const formattedDueAt = formatDate(dueAt);
    let amountExtraStyle = '';
    if (!checkIsPaidScheduledPayment(scheduledPayment)) {
      amountExtraStyle = checkIsDueScheduledPayment(scheduledPayment) ? 'due' : 'late-due';
    }

    return (
      <ListItem button key={id} styleName="list-item scheduled-payment">
        <div styleName="date">{formattedDueAt}</div>
        <div styleName={`amounts-wrapper ${amountExtraStyle}`}>
          <div styleName="amount">{formatAmount(scheduledPayment.principalAmount)}</div>
        </div>
      </ListItem>
    );
  };

  const renderScheduledPaymentList = () => {
    const hasNoScheduledPayments = scheduledPayments.length === 0;
    if (hasNoScheduledPayments && !isFetching) {
      return renderNoScheduledPayment();
    }

    return <List styleName="list scheduled-payments">{scheduledPayments.map(renderScheduledPaymentItem)}</List>;
  };

  return (
    <Col xs={12} sm={6} md={6} styleName="wrapper">
      <Header />
      {isFetching && <LoadingSpinner />}
      {renderScheduledPaymentList()}
    </Col>
  );
};

export default MyScheduledPayments;
